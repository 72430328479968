import React, { useState, useEffect } from "react";
import CountUp from "react-countup";

export default function Stats(props) {
  const [show, updateShow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", function (event) {
      let statSec = document.getElementById("stats-section");
      if (statSec !== null) {
        if (!show)
          if (
            this.scrollY >
            +document.getElementById("stats-section").offsetTop - 300
          ) {
            updateShow(true);
          }
      }
    });
  }, []);
  return (
    <div
      id="stats-section"
      className="stats-section"
      style={{ backgroundImage: "url(stats.jpg)" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-3">
            <div className="stats-box text-center">
              <CountUp start={0} end={show ? 66 : 0} duration={3.75}>
                {({ countUpRef }) => (
                  <div>
                    <h2 ref={countUpRef} />
                  </div>
                )}
              </CountUp>
              {props.lang === "en" ? <p>Years of Experience</p> : null}
              {props.lang === "hi" ? <p>वर्षों का अनुभव</p> : null}
              {props.lang === "pb" ? <p>ਸਾਲਾਂ ਦੇ ਤਜੁਰਬੇ</p> : null}
            </div>
          </div>
          <div className="col-sm-3">
            <div className="stats-box text-center">
              <CountUp start={0} end={show ? 70 : 0} duration={3.75}>
                {({ countUpRef }) => (
                  <div>
                    <h2 ref={countUpRef} />
                  </div>
                )}
              </CountUp>
              {props.lang === "en" ? <p>Award Winning</p> : null}
              {props.lang === "hi" ? <p>पुरस्कार जीते</p> : null}
              {props.lang === "pb" ? <p>ਐਵਾਰਡ ਜਿੱਤੇ</p> : null}
            </div>
          </div>
          <div className="col-sm-3">
            <div className="stats-box text-center">
              <CountUp start={0} end={show ? 30000 : 0}>
                {({ countUpRef }) => (
                  <div>
                    <h2 ref={countUpRef} />
                  </div>
                )}
              </CountUp>
              {props.lang === "en" ? <p>Satisfied Customer</p> : null}
              {props.lang === "hi" ? <p>संतुष्ट ग्राहक</p> : null}
              {props.lang === "pb" ? <p>ਸੰਤੁਸ਼ਟ ਗਾਹਕ</p> : null}
            </div>
          </div>
          <div className="col-sm-3">
            <div className="stats-box text-center">
              <CountUp
                start={0}
                end={show ? 100 : 0}
                suffix="%"
                duration={3.75}
              >
                {({ countUpRef }) => (
                  <div>
                    <h2 ref={countUpRef} />
                  </div>
                )}
              </CountUp>
              {props.lang === "en" ? <p>Ayurvedic Treatment</p> : null}
              {props.lang === "hi" ? <p>आयुर्वेदिक उपचार</p> : null}
              {props.lang === "pb" ? <p>ਆਯੁਰਵੈਦਿਕ ਇਲਾਜ</p> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
