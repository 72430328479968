import React, { useEffect } from "react";

export default function EarlyDischarge(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props]);

  return (
    <div className="treatmentContent">
      {props.lang === "en" ? (
        <>
          <h4>EARLY DISCHARGE</h4>
          <div className="mt-4">
            <iframe
              title="breast enlargement"
              style={{ width: "100%" }}
              height="315"
              src="https://www.youtube.com/embed/qVyECmeXrj8"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullscreen
            ></iframe>
          </div>
          <p>
            Early discharge or Premature ejaculation can become a hindrance in
            enjoying a fulfilling sex life for both the partners. It involves
            the immediate discharge of the sperm when one goes into sexual
            intercourse which results in shamefulness in front of your partner.
            The <strong>main reasons</strong> of it are Tension, depression,
            Consumption of allopathic medicines, intake of drugs, sugar,
            childhood mistakes(masturbation), low pressure of blood in the penis
            which results into the lesser stability of the penis etc. All these
            factors result in a weakness of the nerves thereby resulting in the
            thinness of the sperm and lesser energy in retaining the sperm
            within it. If you are suffering from this problem you need to get
            into regular medication as in future this problem may increase and
            you will fail in satisfying your partner. Then, What is the fun of
            earning so hard when you are not able to satisfy your partner? A
            woman can be satisfied only by sex and not money. So, look into the
            matter seriously and don’t hesitate in sharing this problem with us.
            From our last 60 years experience, we have found an unique formula
            to make you able to get rid of this problem. We believe in giving
            natural treatment which involves Gold- Siver ashes, Shwetavar,
            Musilli, Baikonur, Shilajeet, Shalavdana, Ashwagandha etc. These do
            not have any side effects as they are purely ayurvedic.
          </p>

          <p>
            <strong>Our courses are defined as</strong>
          </p>
          <h5>Honeymoon Course: This course is especially for newlyweds.</h5>
          <ul>
            <li>Takes 1 months to recover.</li>
            <li>
              Includes the high-quality mixture of rare herbs which gives
              positive results
            </li>
            <li>Safe and leaves zero side effect</li>
          </ul>
          <h5>Gold Course: You will see the result after the first dose.</h5>
          <ul>
            <li>Takes 2 months to recover.</li>
            <li>
              Includes the high-quality mixture of rare herbs which gives
              positive results
            </li>
            <li>Safe and leaves zero side effect</li>
          </ul>
          <h5>Silver Course: You will see the result in 4-5 days.</h5>
          <ul>
            <li>Takes 3 months to recover.</li>
            <li>
              Includes the high-quality mixture of rare herbs which gives
              positive results
            </li>
            <li>Safe and leaves zero side effect</li>
          </ul>
          <h5>Payments mode for Indian Customers:</h5>
          <p>
            <strong>Cash On Delivery:</strong> A payment through Paytm is also
            accepted on +91 98728-13954. Payment can be deposited in our bank
            account:
          </p>

          <div className="bankdetails mb-3">
            <div className="row">
              <div className="col-sm-6 col-12">
                <h5>
                  Shraman Health Care ICICI BANK <br />
                  A/c No. 151205000555 <br />
                  IFSC Code. ICIC0001512 <br />
                  Branch. Civil Lines Jalandhar <br />
                </h5>
              </div>
              <div className="col-sm-6 col-12">
                <h5>
                  Punjab National Bank, <br />
                  Name: Dr. Ramdev Kapoor, <br />
                  Account Number: 0235002101719421 <br />
                  IFSC CODE: PUNB0023500 <br />
                </h5>
              </div>
            </div>
          </div>

          <p>
            For online order call us on +91 93177-70773, +91 98728-13954,
            0181-4153002 and WhatsApp us the receipt after making payment and
            send us your address.
          </p>

          <h5>Payment mode for Foreign Customers:</h5>
          <p>
            Payments through Western Money Union, Money Gram, and Express Money
            with the name <strong>Suhel Jain</strong> are accepted.
          </p>
        </>
      ) : null}

      {props.lang === "pb" ? (
        <>
          <h4> ਸ਼ੀਘਰਪਤਨ</h4>
          <div className="mt-4">
            <iframe
              title="breast enlargement"
              style={{ width: "100%" }}
              height="315"
              src="https://www.youtube.com/embed/qVyECmeXrj8"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullscreen
            ></iframe>
          </div>
          <p>
            ਔਰਤ ਦੇ ਨਾਲ ਸੰਭੋਗ ਕਰਨ ਤੋ ਪਹਿਲਾਂ ਜਾਂ ਕਰਦੇ ਸਮੇਂ 2 ਮਿਨਟ ਵਿੱਚ ਜਾਂ ਇੰਝ
            ਕਹੀਏ ਤੁਹਾਡਾ ਜਾਂ ਤੁਹਾਡੇ ਪਾਰਟਨਰ ਦੀ ਤਸੱਲੀ ਹੋਣ ਤੋਂ ਪਹਿਲਾਂ ਹੀ ਵੀਰਯ ਦੇ
            ਨਿਕਲ ਜਾਣ ਨੂੰ ਸ਼ੀਘਰਪਤਨ ਕਹਿੰਦੇ ਹਨ। ਇਸ ਨਾਲ ਮਰੀਜ ਆਪਣੀ ਇੱਛਾ ਤਾਂ ਪੂਰੀ ਨਹੀ
            ਕਰ ਸਕਦਾ ਤੇ ਨਾਲ ਹੀ ਜੀਵਨ ਸਾਥੀ ਦੀ ਇੱਛਾ ਵੀ ਪੂਰੀ ਨਹੀ ਕਰ ਪਾਉਂਦਾ। ਇਹ ਬਹੁਤ
            ਖਤਰਨਾਕ ਬਿਮਾਰੀ ਹੈ ਜੋ ਕਿ ਬਹੁਤ ਸਾਰੇ ਲੋਕਾਂ ਵਿੱਚ ਪਾਈ ਜਾਂਦੀ ਹੈ। ਇਸ ਨਾਲ
            ਘਰੇਲੂ ਅਤੇ ਅਸਲ ਜਿੰਦਗੀ ਚ ਦਰਾਰ ਆਉਣੀ ਸ਼ੁਰੂ ਹੋ ਜਾਂਦੀ ਹੈ।
          </p>
          <p>
            ਇਨਾਂ ਕਾਰਨਾ ਕਰਕੇ ਹੁੰਦਾ ਹੈ ਸ਼ੀਘਰਪਤਨ –<br />
            • ਤਨਾਅ ਤੇ ਟੈਂਸ਼ਨ ਭਰੀ ਜ਼ਿੰਦਗੀ
            <br />
            • ਜ਼ਿਆਦਾ ਤਲਿਆ ਜਾਂ ਖੱਟਾ ਖਾਣ ਨਾਲ/ਗਲਤ ਖਾਣ-ਪੀਣ
            <br />
            • ਸ਼ੂਗਰ/ਬੀ.ਪੀ.
            <br />
            • ਜਿਆਦਾ ਟਾਈਟ ਕੱਪੜੇ ਪਾਉਣ ਨਾਲ
            <br />
            • ਲਿੰਗ ਵਿੱਚ ਤਨਾਅ ਬਹੁਤ ਜ਼ਲਦੀ ਆਉਣ ਕਾਰਨ
            <br />
            • ਜ਼ਿਆਦਾ ਮੁੱਠ ਮਾਰਨ ਨਾਲ
            <br />
            • ਨਸ਼ਾਂ (ਤੰਬਾਕੂ) ਤੇ ਸ਼ਰਾਬ ਦਾ ਸੇਵਨ ਆਦਿ ਕਾਰਨਾ ਕਾਰਨ ਸਰੀਰ ਦੀਆਂ ਨਸਾਂ ਕਮਜ਼ੋਰੀ
            ਹੁੰਦੀਆਂ ਜਾਂਦੀਆਂ ਹਨ ਜਿਸ ਕਾਰਨ ਉਹ ਵੀਰਯ ਨੂੰ ਜ਼ਿਆਦਾ ਸਮੇਂ ਤੱਕ ਨਸਾਂ ਵਿੱਚ
            ਨਹੀਂ ਰੋਕ ਪਾਉਂਦੀਆਂ ਤੇ ਥੋੜੀ ਜਿਹੀ ਉਤੇਜਨਾ ਤੇ ਵੀ ਵੀਰਯ ਜਲਦੀ ਨਿਕਲ ਜਾਂਦਾ ਹੈ।
            <br />
          </p>
          <p>
            ਇਸ ਕਾਰਨ ਤੁਹਾਡਾ ਜੀਵਨ ਸਾਥੀ ਤੁਹਾਡੇ ਤੋਂ ਕਦੇ ਵੀ ਸੰਤੁਸ਼ਟ ਨਹੀਂ ਰਹਿ ਸਕਦਾ ਜਿਸ
            ਕਾਰਨ ਵਿਵਾਹਿਤ ਜੀਵਨ ਵਿੱਚ ਦਰਾਰ ਆ ਸਕਦੀ ਹੈ ਅਤੇ ਤੁਹਾਡਾ ਦਿਨ ਰਾਤ ਪੈਸਾ ਕਮਾਇਆ
            ਕੀ ਕਰੇਗਾ। ਪੈਸਾ ਕਦੇ ਵੀ ਜੀਵਨ ਸਾਥੀ ਦੀ ਸਤੁੰਸ਼ਟੀ ਨਹੀ ਕਰਦਾ। ਔਰਤ ਦੀ ਸੈਕਸ
            ਰਾਹੀ ਪੂਰੀ ਤੱਸਲੀ ਕਰੋ ਤਾਂ ਜਿੰਦਗੀ ਸੁਖੀ ਬਣਦੀ ਹੈ।
            <br /> ਜੇਕਰ ਤੁਹਾਨੂੰ ਵੀ ਹੈ ਸ਼ੀਘਰਪਤਨ ਦੀ ਸਮੱਸਿਆ ਤਾਂ ਹੁਣ ਤੁਹਾਨੂੰ ਘਬਰਾਉਣ
            ਦੀ ਲੋੜ ਨਹੀਂ।
          </p>
          <p>
            ਆਯੂਰਵੈਦ ਦੁਆਰਾ ਇਸ ਬਿਮਾਰੀ 100 ਪ੍ਰਤੀਸ਼ਤ ਇਲਾਜ ਹੋ ਸਕਦਾ ਹੈ। ਅਸੀਂ ਆਪਣੇ ਕਈ
            ਸਾਲਾ ਦੀ ਖੌਜ ਤੇ ਹਜ਼ਾਰਾਂ ਮਰੀਜਾਂ ਦਾ ਇਲਾਜ ਕਰਨ ਦੇ ਤਜਰੂਬੇ ਨਾਲ ਸਾਲਬ ਪੰਜਾ,
            ਅਕਰਕਰਾ, ਅਸ਼ਵਗੰਧਾ, ਸ਼ਤਾਵਰ, ਕੀਕਰ ਫਲੀ, ਸਫ਼ੇਦ ਮੂਸਲੀ, ਸ਼ੀਲਾਜੀਤ, ਕੇਸਰ, ਹੀਰਾ
            ਭਸਮ ਆਦਿ ਦੇ ਮਿਸ਼ਰਣ ਨਾਲ ਮਾਹਿਰ ਡਾਕਟਰਾਂ ਦੀ ਦੇਖ-ਰੇਖ ਵਿੱਚ ਅਜਿਹੀ ਆਯੂਰਵੈਦਿਕ
            ਦਵਾਈ ਬਣਾਈ ਹੈ ਜੋ ਕਿ ਇਸ ਬਿਮਾਰੀ ਨੂੰ ਜੜ ਤੋਂ ਖ਼ਤਮ ਕਰ ਦੇਵੇਗੀ। ਇਸ ਦਾ ਅਸਰ 24
            ਘੰਟਿਆਂ ਦੇ ਅੰਦਰ-ਅੰਦਰ ਸ਼ੁਰੂ ਹੋ ਜਾਂਦਾ ਹੈ। ਸਾਡੀ ਦਵਾਈ ਦਾ ਕੋਰਸ ਕਰਨ ਤੋਂ ਬਾਅਦ
            ਮਰੀਜ਼ ਦੇ ਲਿੰਗ ਵਿੱਚ ਹਰ ਵੇਲੇ ਜੋਸ਼, ਲੰਬਾਈ, ਮੋਟਾਈ ਤੇ ਸਖਤਾਈ ਬਣੀ ਰਹੇਗੀ ਅਤੇ
            ਸੈਕਸ ਕਰਨ ਦੇ ਸਮੇਂ ਵਿੱਚ ਵਾਧਾ ਹੋਵੇਗਾ। ਇਸ ਦਵਾਈ ਦਾ ਕੋਈ ਸਾਈਡ ਇਫੇਕਟ ਨਹੀਂ
            ਹੈ।
            <br /> ਅਸੀਂ ਏਹ ਗਰੰਟੀ ਵੀ ਦੇਂਦੇ ਹਾਂ ਜੇ ਸਾਡੀ ਦਵਾਈ ਦਾ ਅਸਰ ਨਾ ਹੋਇਆ ਤਾਂ
            ਪੈਸੇ ਵਾਪਸ *
          </p>
          <p></p>
          <p>
            <strong>ਸਾਡੇ ਕੋਲ ਇਸ ਬਿਮਾਰੀ ਲਈ ਤਿੰਨ ਤਰ•ਾਂ ਦੇ ਕੋਰਸ ਹਨ-</strong>
          </p>
          <h5>ਸਿਲਵਰ ਕੋਰਸ:</h5>
          <ul>
            <li>ਸਮੱਸਿਆ ਨੂੰ ਠੀਕ ਕਰਨ ਵਿੱਚ 3 ਮਹੀਨੇ ਲਗਦੇ ਹਨ।</li>
          </ul>
          <h5>ਗੋਲਡ ਕੋਰਸ:</h5>
          <ul>
            <li>ਸਮੱਸਿਆ ਨੂੰ ਠੀਕ ਕਰਨ ਵਿੱਚ 2 ਮਹੀਨੇ ਲਗਦੇ ਹਨ।</li>
          </ul>
          <h5>ਹਨੀਮੂਨ ਕੋਰਸ:</h5>
          <ul>
            <li>ਸਮੱਸਿਆ ਨੂੰ ਠੀਕ ਕਰਨ ਵਿੱਚ 40 ਦਿਨ ਲਗਦੇ ਹਨ।</li>
          </ul>
          <p>
            ਇਹਨਾਂ ਸਾਰਿਆਂ ਕੋਰਸਾਂ ਵਿਚ ਦੁਰਲੱਭ ਜੜੀ-ਬੂਟੀਆਂ ਦੇ ਉੱਚ ਗੁਣਵੱਤਾ ਦਾ ਮਿਸ਼ਰਣ ਹੈ
            ਜੋ ਸਕਾਰਾਤਮਕ ਨਤੀਜੇ ਦਿੰਦਾ ਹੈ। ਇਹ ਸੁਰੱਖਿਅਤ ਹੈ ਅਤੇ ਇਹਨਾਂ ਦਵਾਈਆਂ ਦਾ ਕੋਈ
            ਵੀ ਨੁਕਸਾਨ ਨਹੀ ਹੈ।
          </p>
          <h5>ਭੁਗਤਾਨ ਕਰਨ ਲਈ ਇਹਨਾਂ ਬੈਂਕਾਂ ਵਿੱਚ ਪੈਸੇ ਜਮਾਂ ਕਰਵਾ ਸਕਦੇ ਹੋ। </h5>

          <div className="bankdetails mb-3">
            <div className="row">
              <div className="col-sm-6 col-12">
                <h5>
                  ICICI ਬੈਂਕ <br />
                  ਨਾਮ: ਸ਼ਰਮਨ ਹੇਲਥ ਕੇਯਰ <br />
                  ਖਾਤਾ ਨੰਬਰ: 151205000555 <br />
                  IFSC ਕੋਡ: ICIC0001512 <br />
                  ਸ਼ਾਖਾ: ਸਿਵਲ ਲਾਈਨ, ਜਲੰਧਰ
                  <br />
                </h5>
              </div>
              <div className="col-sm-6 col-12">
                <h5>
                  ਪੰਜਾਬ ਨੈਸ਼ਨਲ ਬੈਂਕ, <br />
                  ਨਾਮ: ਸ਼ਰਮਨ ਹੇਲਥ ਕੇਯਰ
                  <br />
                  ਖਾਤਾ ਨੰਬਰ: 0235002101719829
                  <br />
                  IFSC ਕੋਡ: PUNB0023500
                  <br />
                  ਸ਼ਾਖਾ: ਸਿਵਲ ਲਾਈਨ, ਜਲੰਧਰ
                  <br />
                </h5>
              </div>
            </div>
            <p>
              ਆਨਲਾਈਨ ਸਾਮਾਨ ਮੰਗਵਾਉਣ ਲਈ ਸਾਨੂੰ ਇਸ ਨੰਬਰ ਤੇ ਕਾਲ ਕਰੋ +91 93177-70773,
              +91 98728-13954, 0181-4153002 ਅਤੇ ਸਾਨੂੰ ਭੁਗਤਾਨ ਕਰਨ ਤੋਂ ਬਾਅਦ
              Whatsapp ਤੇ ਰਸੀਦ ਅਤੇ ਆਪਣਾ ਪਤਾ ਭੇਜੋ।
            </p>
            <p>
              ਵਿਦੇਸ਼ੀ ਗ੍ਰਾਹਕਾਂ ਲਈ ਭੁਗਤਾਨ ਦਾ ਤਰੀਕਾ –<br />
              ਤੁਸੀਂ ਸੁਹੇਲ ਜੈਨ ਦੇ ਨਾਂ 'ਤੇ ਵੈਸਟਰਨ ਮਨੀ, ਮਨੀ ਗ੍ਰਾਮ ਅਤੇ ਐਕਸਪ੍ਰੈਸ ਮਨੀ
              ਤੇ ਵੀ ਪੈਸਿਆਂ ਦਾ ਭੁਗਤਾਨ ਕਰ ਸਕਦੇ ਹੋ।
            </p>
          </div>
        </>
      ) : null}

      {props.lang === "hi" ? (
        <>
          <h4>शीघ्रपत्न</h4>
          <div className="mt-4">
            <iframe
              title="breast enlargement"
              style={{ width: "100%" }}
              height="315"
              src="https://www.youtube.com/embed/qVyECmeXrj8"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullscreen
            ></iframe>
          </div>
          <p>
            औरत के साथ सैक्स करने से पहले या करते समय 2 मिनट में या ऐसे कहें
            आपके या आपके पार्टनर की तसल्ली होने से पहले ही वीर्य के निकल जाने को
            शीघ्रपत्न कहते हैं। इसके साथ मरीज अपनी इच्छा तो पुरी नहीं कर पाता और
            साथ ही जीवन साथी की इच्छा भी पुरी नहीं कर पाता। यह बहुत खतरनाक
            बीमारी है जो कि बहुत सारे लोगों में पाई जाती है। इसके साथ घरेलू और
            असल जिंदगी में दरार आनी शुरू हो जाती है।
          </p>
          <p>
            इन कारणों के कारण होता है शीघ्रपत्न-
            <br />
            • तनाव और टैंशन भरी जिंदगी
            <br />
            • ज्यादा तला हुआ या खट्टा खाने के कारण/गलत खान-पान
            <br />
            • शुगर/बी.पी.
            <br />
            • ज्यादा टाईट कपड़े पहनने से
            <br />
            • लिंग में तनाव बहुत जल्दी आने के कारण
            <br />
            • ज्यादा हस्तमुथैन के कारण
            <br />
          </p>
          <p>
            नशा (तंबाकू) और शराब का सेवन आदि कारणों के कारण शरीर की नसें कमजोर
            होती जाती हैजिस कारण वह वीर्य को ज्यादा समय तक नसों में नहीं रोक
            पाती और थोड़ी सी उत्तेजना पर भी वीर्य जल्दी निकल जाता है। इस कारण
            आपका जीवन साथी आपसे कभी भी संतुष्ट नहीं रह सकता तथा विवाहित जीवन में
            दरार आ सकती है। आपका दिन रात का कमाया पैसा क्या करेगा अगर आपका जीवन
            साथी आपसे संतुष्ट नहीं। पैसा कभी भी जीवन साथी की संतुष्टि नहीं करता।
            औरत की सैक्स द्वारा पुरी संतुष्टि करने से जिंदगी सुखी बनती है
            <br /> अगर आपको भी है शीघ्रपत्न की समस्या तो अब आपको घबराने की जरूरत
            नहीं।
          </p>
          <p>
            आयुर्वेद द्वारा इस बीमारी का 100 प्रतिशत ईलाज हो सकता है। हमने अपने
            कई सालों की खोज और हजारों मरीजों का ईलाज करने के तर्जुेबे के साथ
            सालब पंजा, अकरकरा, अश्वगंधा, शतावर, कीकर फली, सफेद मूसली, शीलाजीत,
            केसर, हीरा भस्म आदि के मिश्रण से ऐसी आयुर्वेदिक दवाई बनाई है जो इस
            बीमारी को जड़ से खत्म कर देगी। इसक असर 24 घंटेे के अन्दर-अन्दर आना
            शुरु हो जाता है। हमारी दवा का कोर्स करने के बाद मरीज के लिंग में हर
            समय जोश, लम्बाई, मोटाई और सख्ताई बनी रहेगी और सैक्स करने के समय में
            भी बढ़ावा होगा। इसका कोई साइड इफेक्ट नहीं है।
            <br /> हम यह गारंटी भी देते हैं अगर हमारी दवाई का असर न हुआ तो पैसे
            वापिस।
          </p>
          <p>
            <strong>हमारे पास तीन तरह के कोर्स हैं।</strong>
          </p>
          <h5>सिल्वर कोर्स-</h5>
          <ul>
            <li>समस्या को ठीक करने में 3 महीने लगते हैं।</li>
          </ul>
          <h5>गोल्ड कोर्स-</h5>
          <ul>
            <li>समस्या को ठीक करने में 2 महीने लगते हैं।</li>
          </ul>
          <h5>हनीमून कोर्स-</h5>
          <ul>
            <li>समस्या को ठीक करने में 40 दिन लगते हैं।</li>
          </ul>
          <p>
            इन सभी कोर्सों में दुर्लभ जड़ी-बूटियां के उच्च गुणवत्ता का मिश्रण है
            जो साकारात्मक नतीजे देते हैं।
          </p>
          <p>यह सुरक्षित है और इन दवाओं का कोई भी नुकसान नहीं है।</p>
          <h5>भुगतान करने के लिए इन बैंकों में पैसे जमां करवा सकते हो। </h5>

          <div className="bankdetails mb-3">
            <div className="row">
              <div className="col-sm-6 col-12">
                <h5>
                  ICICI बैंक <br />
                  नाम- श्रमण हैल्थकेयर <br />
                  खाता नंबर- 151205000555 <br />
                  IFSC कोड- ICIC0001512 <br />
                  शाखा- सिवल लाईन, जालन्धर
                  <br />
                </h5>
              </div>
              <div className="col-sm-6 col-12">
                <h5>
                  पंजाब नैशनल बैंक, <br />
                  नाम- श्रमण हैल्थकेयर
                  <br />
                  खाता नंबर- 0235002101719829
                  <br />
                  IFSC कोड-PUNB0023500
                  <br />
                  शाखा- सिविल लाइन, जालन्धर
                  <br />
                </h5>
              </div>
            </div>
            <p>
              आनलाइन दवाईयां मंगवाने के लिए हमें इस नंबर पर कॉल करो +91
              93177-70773, +91 98728-13954, 0181-4153002 और हमें भुगतान करने के
              बाद Whatsapp पर रसीद और अपना पता भेजें। –<br />
              विदेशी ग्राहकों के लिए भुगतान का तरीका-
            </p>
            <p>
              आप सुहेल जैन के नाम पर वैस्टर्न युनियन, मनी ग्राम और एक्सप्रैस मनी
              आदि द्वारा भी भुगतान कर सकते हो।
            </p>
          </div>
        </>
      ) : null}
    </div>
  );
}
