import React from "react";
import { Link } from "react-router-dom";

export default function TreatmentOffered(props) {
  return (
    <section className="treatmentoffer treatmentoffered">
      <div className="container">
        <div className="col-sm-12 text-center mb-4" hidden>
          <h4>Treatment We Offer</h4>
          <h5 hidden>We Provide You The Best Treatment For Sex Problem</h5>
        </div>
        <div className="col-sm-12 mt-4 text-center  px-0">
          <Link className="btn btntreatmentweoffer">
            {props.lang === "en" ? "Treatments We Offer" : null}
            {props.lang === "hi" ? "ईलाज जो हम करते हैं " : null}
            {props.lang === "pb" ? "ਇਲਾਜ ਜੋ ਅਸੀਂ ਕਰਦੇ ਹਾਂ" : null}
            {/* <span className="btnclickhere">Click Here</span> */}
          </Link>
        </div>
        <div className="row" hidden>
          <div className="col-sm-2">
            <div className="treatment-box animate__animated animate__fadeInLeft">
              <Link to="/treatment/sex-problem">
                <div
                  className="treatmentBox"
                  style={{ backgroundImage: "url(sex.jpg)" }}
                >
                  <div className="tile">
                    <img
                      src="treatment/gender.png"
                      alt="sex problem"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <p>Sex Problem</p>
              </Link>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="treatment-box animate__animated animate__fadeInLeft">
              <Link to="/treatment/low-sperm-count">
                <div
                  className="treatmentBox"
                  style={{ backgroundImage: "url(sperm.jpg)" }}
                >
                  <div className="tile">
                    <img
                      src="treatment/sperm.png"
                      alt="sex problem"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <p>Low Sperm Count</p>
              </Link>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="treatment-box animate__animated animate__fadeInLeft">
              <Link to="/treatment/breast-enlargement">
                <div
                  className="treatmentBox"
                  style={{ backgroundImage: "url(breast.jpg)" }}
                >
                  <div className="tile">
                    <img
                      src="treatment/breast.png"
                      alt="sex problem"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <p>Breast Enlargement</p>
              </Link>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="treatment-box animate__animated animate__fadeInLeft">
              <Link to="/treatment/penis-enlargement">
                <div
                  className="treatmentBox"
                  style={{ backgroundImage: "url(penis.jpg)" }}
                >
                  <div className="tile">
                    <img
                      src="treatment/penis.png"
                      alt="sex problem"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <p>Penis Enlargement</p>
              </Link>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="treatment-box animate__animated animate__fadeInLeft">
              <Link to="/treatment/weight-gain">
                <div
                  className="treatmentBox"
                  style={{ backgroundImage: "url(weight-gain.png)" }}
                >
                  <div className="tile">
                    <img
                      src="treatment/gain.png"
                      alt="sex problem"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <p>Weight Gain</p>
              </Link>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="treatment-box animate__animated animate__fadeInLeft">
              <Link to="/treatment/nightfall">
                <div
                  className="treatmentBox"
                  style={{ backgroundImage: "url(nightfall.PNG)" }}
                >
                  <div className="tile">
                    <img
                      src="treatment/nightfall.png"
                      alt="sex problem"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <p>Nightfall</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-6 mx-auto" hidden>
          <div className="row">
            <div className="col-sm-4">
              <div className="treatment-box animate__animated animate__fadeInLeft">
                <Link to="/treatment/early-discharge">
                  <div
                    className="treatmentBox"
                    style={{ backgroundImage: "url(discharge.jpg)" }}
                  >
                    <div className="tile">
                      <img
                        src="treatment/early-discharge.svg"
                        alt="sex problem"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <p>Early Discharge</p>
                </Link>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="treatment-box animate__animated animate__fadeInLeft">
                <Link to="/treatment/arthritis-and-joint">
                  <div
                    className="treatmentBox"
                    style={{ backgroundImage: "url(sex.jpg)" }}
                  >
                    <div className="tile">
                      <img
                        src="treatment/joint.png"
                        alt="sex problem"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <p>Joint Pain</p>
                </Link>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="treatment-box animate__animated animate__fadeInLeft">
                <Link to="/treatment/sugar">
                  <div
                    className="treatmentBox"
                    style={{ backgroundImage: "url(sugar.jpeg)" }}
                  >
                    <div className="tile">
                      <img
                        src="treatment/gender.png"
                        alt="sex problem"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <p>Sugar</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
