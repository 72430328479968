// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import Header from "./Homepage/Header";
// import Footer from "./Homepage/Footer";

// export default function Blog(props) {
//   useEffect(() => {
//     // console.log(props)
//     window.scrollTo(0, 0);
//   }, []);

//   let [lang, setLang] = useState("en");
//   // const [comp, updateComponent] = useState(null);

//   useEffect(() => {
//     lang = localStorage.getItem("lang");
//     setLang(lang);
//   }, [lang]);

//   function changeLang(e) {
//     localStorage.setItem("lang", e.target.value);
//     setLang(e.target.value);
//   }

//   return (
//     <div className="blog-section">
//       <Header {...props} changeLang={changeLang} lang={lang} />

//       <div
//         id="siteContent"
//         className="page-heading animate__animated animate__fadeInDown"
//         style={{ backgroundImage: "url(ayurved02.jpg)" }}
//       >
//         <div className="container">
//           <div className="row">
//             <div className="col-sm-12">
//               { lang === "hi" ?  <h2>ब्लॉग</h2> : null}
//               { lang === "en" ? <h2>Blog</h2> : null}
//               { lang === "pb" ? <h2>ਬਲਾੱਗ</h2> : null}

//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="blog-content my-5">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-4">
//               {lang === "hi" ? (
//                 <div className="card wow animate__animated animate__fadeInLeft mb-3">
//                   <Link to="">
//                     <img
//                       className="card-img-top img-fluid"
//                       src="sugar-hindi.jpg"
//                       alt="hair growth"
//                     />
//                   </Link>
//                   <div className="card-body p-3">
//                     <h5 className="card-title">
//                       शूगर और किसी भी तरह की शारीरिक कमजोरी को खत्म कर इम्यूनिटी
//                       भी बढ़ाता है यह देसी ईलाज
//                     </h5>
//                     <p className="card-text">
//                       आज जहां पूरी दुनिया में इम्यूनिटी एक बहुत बड़ा मुद्दा बन
//                       चुका है। उसी तरह महिलाओं और पुरुषों में शूगर और शारीरिक
//                       कमजोरी भी आज के युग की गंभीर बीमारियाँ हैं।
//                     </p>
//                     <Link to="/blog-post" className="btn btnviewmore">
//                       अधिक जानिए
//                     </Link>
//                   </div>
//                 </div>
//               ) : null}
//               {lang === "pb" ? (
//                 <div className="card wow animate__animated animate__fadeInLeft mb-3">
//                   <Link to="">
//                     <img
//                       className="card-img-top img-fluid"
//                       src="sugar-punjabi.jpg"
//                       alt="hair growth"
//                     />
//                   </Link>
//                   <div className="card-body p-3">
//                     <h5 className="card-title">
//                       ਸ਼ੂਗਰ ਅਤੇ ਕਿਸੇ ਵੀ ਤਰ੍ਹਾਂ ਦੀ ਸ਼ਾਰੀਰਿਕ ਕਮਜ਼ੋਰੀ ਨੂੰ ਖਤਮ ਕਰ
//                       ਇਮਿਊਨਿਟੀ ਵੀ ਵਧਾਉਂਦਾ ਹੈ ਇਹ ਦੇਸੀ ਇਲਾਜ
//                     </h5>
//                     <p className="card-text">
//                       ਅੱਜ ਜਿੱਥੇ ਪੂਰੀ ਦੁਨੀਆਂ ਵਿੱਚ ਇਮਿਊਨਿਟੀ ਇੱਕ ਬਹੁਤ ਵੱਡਾ ਮੁੱਦਾ ਬਣ
//                       ਚੁੱਕਾ ਹੈ। ਉਸੇ ਤਰ੍ਹਾਂ ਔਰਤਾਂ ਤੇ ਮਰਦਾਂ ਵਿੱਚ ਸ਼ੂਗਰ ਤੇ ਮਰਦਾਨਾ
//                       ਕਮਜ਼ੋਰੀ ਵੀ ਅੱਜ ਦੇ ਯੁਗ ਦੀਆਂ ਗੰਭੀਰ ਬਿਮਾਰੀਆਂ ਹਨ।
//                     </p>
//                     <Link to="/blog-post" className="btn btnviewmore">
//                       ਹੋਰ ਜਾਣੋ
//                     </Link>
//                   </div>
//                 </div>
//               ) : null}
//               {lang === "en" ? (
//                 <div className="card wow animate__animated animate__fadeInLeft mb-3">
//                   <Link to="">
//                     <img
//                       className="card-img-top img-fluid"
//                       src=""
//                       alt="hair growth"
//                     />
//                   </Link>
//                   <div className="card-body p-3">
//                     <h5 className="card-title">
//                       Best Hair Growth Supplements In India
//                     </h5>
//                     <p className="card-text">
//                       Does thin and dry hair embarrass you among people? Are you
//                       looking for the top hair growth supplements in India like
//                       hair tablets, capsules etc.?
//                     </p>
//                     <Link to="/blog-post" className="btn btnviewmore">
//                       Know More
//                     </Link>
//                   </div>
//                 </div>
//               ) : null}
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer lang={lang} />
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import Header from "./Homepage/Header";
import Footer from "./Homepage/Footer";
import ReactGA from "react-ga";

ReactGA.initialize("UA-171309197-1");

export default function BlogPost(props) {
  useEffect(() => {
    // console.log(props)
    window.scrollTo(0, 0);
  }, []);

  let [lang, setLang] = useState("en");
  // const [comp, updateComponent] = useState(null);

  useEffect(() => {
    lang = localStorage.getItem("lang");
    ReactGA.pageview(props.match.url + " " + lang);
    setLang(lang);
  }, [lang]);

  function changeLang(e) {
    localStorage.setItem("lang", e.target.value);
    setLang(e.target.value);
  }
  return (
    <div className="blogpost-section">
      <Header {...props} changeLang={changeLang} lang={lang} />
      <div
        id="siteContent"
        className="page-heading"
        style={{ backgroundImage: "url(ayurved02.jpg)" }}
      >
        <div className="container">
          <div className="row">
            {/* <div className="col-sm-12">
                            { lang === "en" ?  <h2>Sugar</h2> :null}
                            { lang === "hi" ?  <h2>शुगर</h2> :null}
                            { lang === "pb" ?  <h2>ਸ਼ੂਗਰ</h2> :null}
                        </div> */}
          </div>
        </div>
      </div>
      <div className="blogpost-content my-5">
        {lang === "pb" ? (
          <div className="container">
            <div className="row">
              <div class="col-12">
                <h4 class="wow animate__animated animate__fadeInLeft">
                  ਸ਼ੂਗਰ ਅਤੇ ਕਿਸੇ ਵੀ ਤਰ੍ਹਾਂ ਦੀ ਸ਼ਾਰੀਰਿਕ ਕਮਜ਼ੋਰੀ ਨੂੰ ਖਤਮ ਕਰ ਇਮਿਊਨਿਟੀ
                  ਵੀ ਵਧਾਉਂਦਾ ਹੈ ਇਹ ਦੇਸੀ ਇਲਾਜ
                </h4>
                {/* <p class="wow postedtime animate__animated animate__fadeInRight">Posted at 06:33PM in shraman-blogs by admin</p> */}
              </div>
              <div class="col-md-12">
                <div class="leftsection">
                  <div class="post-img position-relative wow animate__animated animate__fadeInLeft animate__delay-1s">
                    <div
                      id="carouselExampleIndicators"
                      class="carousel slide"
                      data-ride="carousel"
                    >
                      <ol class="carousel-indicators">
                        <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to="0"
                          class="active"
                        ></li>
                        <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to="1"
                        ></li>
                        <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to="2"
                        ></li>
                      </ol>
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <img
                            class="d-block w-100"
                            src="/sugar-punjabi.jpg"
                            alt="ayurvedic"
                          />
                        </div>
                        {/* <div class="carousel-item">
                                                <img class="d-block w-100" src="/hair-growth.png" alt="ayurvedic" />
                                            </div>
                                            <div class="carousel-item">
                                                <img class="d-block w-100" src="/ayurvedic.jpg" alt="ayurvedic" />
                                            </div> */}
                      </div>
                      <a
                        class="carousel-control-prev"
                        href="#carouselExampleIndicators"
                        role="button"
                        data-slide="prev"
                      >
                        <span
                          class="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="sr-only">Previous</span>
                      </a>
                      <a
                        class="carousel-control-next"
                        href="#carouselExampleIndicators"
                        role="button"
                        data-slide="next"
                      >
                        <span
                          class="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="sr-only">Next</span>
                      </a>
                    </div>
                  </div>
                  <div class="content-section mt-4 wow animate__animated animate__fadeInUp animate__delay-2s">
                    <p>
                      ਅੱਜ ਜਿੱਥੇ ਪੂਰੀ ਦੁਨੀਆਂ ਵਿੱਚ ਇਮਿਊਨਿਟੀ ਇੱਕ ਬਹੁਤ ਵੱਡਾ ਮੁੱਦਾ ਬਣ
                      ਚੁੱਕਾ ਹੈ। ਉਸੇ ਤਰ੍ਹਾਂ ਔਰਤਾਂ ਤੇ ਮਰਦਾਂ ਵਿੱਚ ਸ਼ੂਗਰ ਤੇ ਮਰਦਾਨਾ
                      ਕਮਜ਼ੋਰੀ ਵੀ ਅੱਜ ਦੇ ਯੁਗ ਦੀਆਂ ਗੰਭੀਰ ਬਿਮਾਰੀਆਂ ਹਨ।
                    </p>
                    <p>
                      <strong>ਕੀ ਹੁੰਦੀ ਹੈ ਸ਼ੂਗਰ ?</strong> ਅੱਜ ਪੂਰੀ ਦੁਨੀਆਂ ਵਿੱਚ
                      ਇੱਕ ਤਿਹਾਈ ਤੋਂ ਜਿਆਦਾ ਲੋਕ ਸ਼ੂਗਰ ਦੀ ਬਿਮਾਰੀ ਤੋਂ ਪਰੇਸ਼ਾਨ ਹਨ। ਜਦੋਂ
                      ਸ਼ਰੀਰ ਦੇ ਪੇਨੀਕ੍ਰਿਆਜ ਵਿੱਚ ਇੰਸੁਲੀਨ ਦਾ ਬਹਾਵ ਘੱਟ ਹੋ ਜਾਣ ਦੇ ਕਾਰਨ
                      ਖੂਨ ਵਿੱਚ ਗੋਲੋਕੋਜ ਦੀ ਮਾਤਰਾ ਆਮ ਨਾਲੋਂ ਜਿਆਦਾ ਹੋ ਜਾਂਦੀ ਹੈ ਤਾਂ
                      ਇਸ ਸਥਿਤੀ ਨੂੰ ਡਾਈਬੀਟੀਜ/ਸ਼ੂਗਰ ਕਿਹਾ ਜਾਂਦਾ ਹੈ।
                    </p>
                    <p>
                      <strong>ਕੀ ਹੁੰਦੀ ਹੈ ਸ਼ਾਰੀਰਿਕ ਕਮਜੋਰੀ ? </strong> ਜਦੋਂ ਕੋਈ
                      ਵਿਅਕਤੀ ਜਾਂ ਔਰਤ ਆਪਣੇ ਪਤੀ/ਪਤਨੀ/ਸਹੇਲੀ/ਦੋਸਤ ਨੂੰ ਸ਼ਾਰੀਰਿਕ ਤੌਰ ਤੇ
                      ਸੰਤੁਸ਼ਟ ਨਹੀਂ ਕਰ ਪਾਉਂਦਾ ਉਸ ਨੂੰ ਮਰਦਾਨਾ/ਸ਼ਾਰੀਰਿਕ ਕਮਜੋਰੀ ਕਹਿੰਦੇ
                      ਹਨ। ਮਰਦਾਨਾ ਕਮਜੋਰੀ ਕਈ ਤਰ੍ਹਾਂ ਦੀ ਹੁੰਦੀ ਹੈ ਜਿਵੇਂ ਕਿ ਇੱਛਾ ਨ
                      ਹੋਣਾ, ਨੀਲ ਸ਼ੂਕਰਾਣੂ, ਢੀਲਾਪਨ, ਸਮਾਂ ਘੱਟ ਲੱਗਣਾ, ਧਾਂਤ, ਅੰਗਾਂ ਦੇ
                      ਵਿਕਾਸ ਚ ਕਮੀ, ਵੱਜਣ ਨ ਵੱਧਣਾ, ਜੋੜਾਂ ਦਾ ਦਰਦ, ਮੋਟਾਪਾ । ਇਸੇ
                      ਤਰ੍ਹਾਂ ਔਰਤਾਂ ਦੇ ਵਿੱਚ ਵੀ ਕਈ ਤਰ੍ਹਾਂ ਦੀ ਸ਼ਾਰੀਰਿਕ ਕਮਜ਼ੋਰੀ ਪਾਈ
                      ਜਾਂਦੀ ਹੈ ਜਿਵੇਂ ਕਿ ਉਨ੍ਹਾਂ ਦੇ ਅੰਗਾਂ ਦੇ ਵਿਕਾਸ ਵਿੱਚ ਕਮੀ,
                      ਲੋਕੋਰੀਆ, ਔਰਤਾਂ ਦੇ ਗੁਪਤ ਰੋਗ ਆਦਿ।
                    </p>
                    <p>
                      <strong>ਇਨ੍ਹਾਂ ਬਿਮਾਰੀਆਂ ਦੇ ਮੁੱਖ ਕਾਰਨ- </strong> ਗਲਤ
                      ਖਾਣ-ਪੀਣ, ਟੈਂਸ਼ਨ, ਡਿਪਰੇਸ਼ਨ, ਅੱਜ-ਕੱਲ ਦਾ ਲਾਈਫ਼ ਸਟਾਈਲ, ਬਾੱਡੀ ਮਾਸ
                      ਇੰਡੈਕਸ ਦਾ ਜ਼ਰੂਰਤ ਤੋਂ ਘੱਟ ਜਾਂ ਵੱਧ ਹੋਣਾ, ਸਾਰੀਰਿਕ ਕਸਰਤ ਨ ਕਰਨਾ,
                      ਜਰੂਰਤ ਤੋਂ ਜਿਆਦਾ ਖਾਣਾ, ਟਾਈਟ ਕੱਪੜੇ, ਕਮਜੋਰ ਪਾਚਨ ਤੰਤਰ, ਬੀ.ਪੀ.,
                      ਮੋਟਾਪਾ, ਅੰਗੇਰੇਜੀ ਦਵਾਈਆਂ, ਨਸ਼ਾਂ ਆਦਿ ਹੁੰਦੇ ਹਨ। ਇਹਨਾਂ ਕਾਰਨਾਂ
                      ਕਾਰਨ ਸ਼ਰੀਰ ਦਾ ਪਾਚਨ ਤੰਤਰ, ਪੇਨੀਕ੍ਰਿਆਜ, ਖੂਨ ਦੀਆਂ ਨਾੜਾਂ, ਨਸਾਂ
                      ਕਮਜੋਰ ਹੋ ਜਾਂਦੀਆਂ ਹਨ ਜਾਂ ਇਨ੍ਹਾਂ ਵਿੱਚ ਬਲੌਕੇਜ ਆ ਜਾਂਦੀ ਹੈ ਤਾਂ
                      ਅਸੀ ਇਨ੍ਹਾਂ ਬਿਮਾਰੀਆਂ ਦੇ ਸ਼ਿਕਾਰ ਹੋ ਜਾਂਦੇ ਹਾਂ।
                    </p>
                    <p>
                      <strong>
                        ਕਿੰਨੇ ਗੰਭੀਰ ਹੁੰਦੇ ਹਨ ਇਨ੍ਹਾਂ ਬਿਮਾਰੀਆਂ ਦੇ ਨਤੀਜੇ ?{" "}
                      </strong>{" "}
                      ਇਨ੍ਹਾਂ ਬਿਮਾਰੀਆਂ ਦੇ ਨਤੀਜੇ ਵੀ ਬਹੁਤ ਗੰਭੀਰ ਹੁੰਦੇ ਹਨ ਜਿਵੇਂ ਕਿ
                      ਸ਼ੂਗਰ ਰੋਗੀ ਨੂੰ ਲੱਗੀ ਸੱਟ ਛੇਤੀ ਨਹੀਂ ਭਰਦੀ ਅਤੇ ਖਾਣ-ਪੀਣ ਦਾ ਵੀ
                      ਬਹੁਤ ਧਿਆਨ ਰੱਖਣਾ ਪੈਂਦਾ ਹੈ। ਸ਼ੂਗਰ ਰੋਗੀ ਨੂੰ ਮਰਦਾਨਾ ਕਮਜੋਰੀ ਵੀ
                      ਹੋ ਜਾਂਦੀ ਹੈ। ਇਨਾਂ ਕਾਰਨ ਉਸਦਾ ਜੀਵਨ ਦੁੱਖਾ ਤੇ ਤਕਲੀਫਾ ਵਿੱਚ ਹੀ
                      ਨਿਕਲਦਾ ਹੈ। ਉਂਝ ਹੀ ਮਰਦਾਨਾ ਕਮਜੋਰੀ ਵਾਲਾ ਵਿਅਕਤੀ ਆਪਣੀ
                      ਪਤਨੀ/ਸਹੇਲੀ ਅੱਗੇ ਬਾਰ-ਬਾਰ ਸ਼ਰਮਿੰਦਾ ਹੁੰਦਾ ਹੈ। ਮਰਦਾਨਾ ਕਮਜੋਰੀ
                      ਤੋਂ ਪੀੜਿਤ ਵਿਅਕਤੀ ਵਿਆਹ ਕਰਵਾਉਣ ਤੋਂ ਵੀ ਘਬਰਾਉਂਦਾ ਹੈ। ਜੇਕਰ ਉਹ
                      ਵਿਆਹਿਆ ਹੋਇਆ ਹੈ ਤਾਂ ਉਸਦੇ ਵਿਵਾਹਿਕ ਜੀਵਨ ਵਿੱਚ ਦਰਾਰ ਆ ਸਕਦੀ ਹੈ।
                      ਇਸ ਕਰਕੇ ਸਾਨੂੰ ਸਮੇਂ ਸਿਰ ਇਨ੍ਹਾਂ ਬਿਮਾਰੀਆਂ ਦਾ ਇਲਾਜ ਕਰਵਾ ਲੈਣਾ
                      ਚਾਹੀਦਾ ਹੈ। ਸ਼ੂਗਰ ਤੋਂ ਬਚਣ ਲਈ ਲੋਕ ਇੰਨਸੁਲੀਨ ਦੇ ਇੰਜੈਕਸ਼ਨ ਤੇ
                      ਅੰਗਰੇਜੀ ਦਵਾਈ ਦਾ ਸੇਵਨ ਕਰਦੇ ਹਨ। ਠੀਕ ਇਸੇ ਤਰ੍ਹਾਂ ਮਰਦਾਨਾ ਕਮਜੋਰੀ
                      ਤੋਂ ਬੱਚਣ ਲਈ ਬਚਣ ਲਈ ਕਈ ਤਰ੍ਹਾਂ ਦੀਆਂ ਅੰਗਰੇਜ਼ੀ ਦਵਾਈਆਂ/ਸਪਰੇਅ ਦਾ
                      ਇਸਤੇਮਾਲ ਕਰਦੇ ਹਨ ਜੋ ਇੱਕ ਸਮੇਂ ਤੱਕ ਇਨ੍ਹਾਂ ਸਮੱਸਿਆਵਾਂ ਦਾ ਹੱਲ
                      ਤਾਂ ਕੱਢ ਦਿੰਦਾ ਹੈ ਪਰ ਜਾਂ ਤਾਂ ਉਨ੍ਹਾਂ ਦੀ ਆਦਤ ਪੈ ਜਾਂਦੀ ਹੈ ਜਾਂ
                      ਉਨ੍ਹਾਂ ਦੇ ਗੰਭੀਰ ਸਾਈਡ ਇਫੇਕਟ ਸ਼ਰੀਰ ਤੋਂ ਭੁਗਤਨੇ ਪੈਂਦੇ ਹਨ।
                    </p>
                    <p>
                      <strong>ਕੀ ਹੈ ਇਹਨਾਂ ਬਿਮਾਰੀਆਂ ਦਾ ਇਲਾਜ- </strong> ਜੇਕਰ
                      ਤੁਹਾਨੂੰ ਮਰਦਾਨਾ ਕਮਜੋਰੀ, ਸ਼ੂਗਰ, ਵਜਨ ਨ ਵੱਧਣਾ, ਮੋਟਾਪਾ, ਗਠੀਆ
                      ਜੋੜਾਂ ਦਾ ਦਰਦ, ਨੀਲ ਸ਼ੂਕਰਾਣੂ, ਸੰਪਨ ਦੋਸ਼ ਅਤੇ ਇੰਝ ਹੀ ਔਰਤਾਂ ਦੇ
                      ਗੁਪਤ ਰੋਗ ਜਿਵੇਂ ਕਿ ਲੋਕੋਰੀਆ, ਛਾਤੀ ਦਾ ਆਕਾਰ ਨ ਵੱਧਣਾ, ਇੱਛਾ ਦੀ
                      ਕਮੀ ਵਰਗੀ ਕੋਈ ਵੀ ਬਿਮਾਰੀ ਹੈ ਤਾਂ ਤੁਹਾਨੂੰ ਘਬਰਾਉਣ ਦੀ ਲੋੜ ਨਹੀਂ।
                      ਆਯੂਰਵੈਦ ਦੁਆਰਾ ਇਨ੍ਹਾਂ ਸਾਰੀਆਂ ਬਿਮਾਰੀਆਂ ਦਾ 100 ਫੀਸਦੀ ਹੱਲ ਸੰਭਵ
                      ਹੈ ਅਤੇ ਹੁਣ ਤੁਹਾਡੇ ਪਤੀ, ਪਤਨੀ, ਸਹੇਲੀ ਨੂੰ ਸ਼ਿਕਾਇਤ ਦਾ ਮੌਕਾ ਨਹੀਂ
                      ਮਿਲੇਗਾ ਕਿਉਂਕਿ ਜਲੰਧਰ ਪੰਜਾਬ ਸਿਥਤ ਸ਼ਰਮਣ ਹੈਲਥਕੇਅਰ ਨੇ ਆਪਣੇ ਕਈ
                      ਸਾਲਾਂ ਦੀ ਖੌਜ ਤੇ ਹਜਾਰਾਂ ਮਰੀਜਾਂ ਦਾ ਇਲਾਜ ਕਰਨ ਦੇ ਤਜੁਰਬੇ ਨਾਲ
                      ਇਨ੍ਹਾਂ ਸਾਰੀਆਂ ਬਿਮਾਰੀਆਂ ਦੀਆਂ ਮਾਹਿਰ ਡਾਕਟਰਾਂ ਦੀ ਦੇਖ-ਰੇਖ ਵਿੱਚ
                      ਅਜਿਹੀਆਂ ਆਯੂਰਵੈਦਿਕ ਦਵਾਈਆਂ ਬਣਾਈਆਂ ਹਨ ਜੋ ਇਨ੍ਹਾਂ ਸਾਰੀਆਂ
                      ਬਿਮਾਰੀਆਂ ਨੂੰ ਜੜ੍ਹ ਤੋਂ ਹੀ ਖਤਮ ਕਰ ਦੇਣਗੀਆਂ ਤੇ ਸ਼ਰੀਰ ਦੀ ਰੋਗ
                      ਪ੍ਰਤੀਰੋਧਕ ਸ਼ਮਤਾ ਨੂੰ ਵੀ ਵਧਾਉਂਦੀਆਂ ਹਨ ਤੇ ਨਾਲ ਹੀ ਇਸਦਾ ਕੋਈ ਸਾਈਡ
                      ਇਫੇਕਟ ਵੀ ਨਹੀਂ ਹੈ। ਸ਼ਰਮਣ ਹੈਲਥਕੇਅਰ ਇਹ ਦਾਅਵਾ ਵੀ ਕਰਦੇ ਹਨ ਕਿ
                      ਜੇਕਰ ਦਵਾਈ ਅਸਰ ਨਹੀਂ ਕਰਦੀ ਤਾਂ ਪੈਸੇ ਵਾਪਿਸ। ਇਹ ਦੁਨੀਆਂ ਦੇ ਹਰ
                      ਕੋਨੇ ਵਿੱਚ ਦਵਾਈ ਭੇਜ ਸਕਦੇ ਹਨ।
                    </p>
                    <p>
                      <strong>ਨੋਟ</strong> ਸ਼ੂਗਰ ਦੀ ਦਵਾਈ 10,000/- ਰੁਪਏ ਮਹੀਨੇ ਦੀ
                      ਕੀਮਤ ਵਿੱਚ ਲੈਕੇ ਬਲੱਡ ਸ਼ੂਗਰ ਤੋਂ ਰਾਹਤ ਪਾਓ ਅਤੇ 10,000/- ਰੁਪਏ
                      ਮੁੱਲ ਦੀ ਦਵਾਈ ਨਾਲ 4 ਤੋਂ 7 ਕਿਲੋ ਮਹੀਨੇ ਵਿੱਚ ਵਜ਼ਨ ਘਟਾ ਜਾਂ ਵਧਾ
                      ਸਕਦੇ ਹੋ ਜਾਂ ਜੋੜਾਂ ਦੇ ਦਰਦ ਤੋਂ ਹਮੇਸ਼ਾ ਲਈ ਰਾਹਤ ਪਾ ਸਕਦੇ ਹੋ। ਇਹ
                      ਸਾਰੀਆਂ ਦਵਾਈਆਂ ਇੱਕਦਮ ਸ਼ੁੱਧ ਭਸਮਾਂ ਨਾਲ ਬਣੀਆਂ ਹਨ। ਮਰਦਾਨਾ ਕਮਜ਼ੋਰੀ
                      ਜਾਂ ਕਿਸੇ ਹੋਰ ਕਮਜ਼ੋਰੀ ਦੀ ਦਵਾਈ ਭਾਰਤ ਵਿੱਚ ਮੰਗਵਾਉਣ ਲਈ ਇਸ ਨੰਬਰ
                      ਤੇ +91 98728-13954 ਅਤੇ ਭਾਰਤ ਤੋਂ ਬਾਹਰ ਦਵਾਈ ਮੰਗਵਾਉਣ ਲਈ +91
                      93177-70773 ਤੇ ਸੰਪਰਕ ਕਰੋ। ਜ਼ਿਆਦਾ ਜਾਣਕਾਰੀ ਲਈ ਇਹਨਾਂ ਦੀ
                      ਵੈਬਸਾਈਟ ਪੜ੍ਹੋ ਜਾਂ ਕਮਜ਼ੋਰੀ ਸੰਬੰਧੀ ਵੀਡਿਓ ਦੇਖਣ ਲਈ ਇਸ ਲਿੰਕ ਤੇ
                      ਕਲਿਕ ਕਰੋ।
                    </p>
                  </div>
                </div>
                {/* <div class="importanttags">
                                <ul>
                                    <li class="wow animate__animated animate__fadeInUp animate__delay-3s">
                                        Health Care
                                    </li>
                                    <li class="wow animate__animated animate__fadeInUp animate__delay-3s">
                                        Physical Illness
                                    </li>
                                    <li class="wow animate__animated animate__fadeInUp animate__delay-3s">
                                        Blood Sugar
                                    </li>
                                    <li class="wow animate__animated animate__fadeInUp animate__delay-3s">
                                        Ayurvedi Medicine
                                    </li>
                                    <li class="wow animate__animated animate__fadeInUp animate__delay-3s">
                                        Hair Growth
                                    </li>
                                    <li class="wow animate__animated animate__fadeInUp animate__delay-3s">
                                        Joint Problem
                                    </li>
                                    <li class="wow animate__animated animate__fadeInUp animate__delay-3s">
                                        Weight Loss
                                    </li>
                                </ul>
                            </div> */}
                {/* <div class="next-previous mt-5">
                                <button class="btn btnprevious bglight">Previous</button>
                                <button class="btn btnnext bg-dark text-white">Next</button>
                            </div> */}
              </div>
              {/* <div class="col-md-4">
                            <div class="rightsection">
                                <h4 class="mb-2">Recent Post</h4>
                                <div class="recentpost wow animate__animated animate__fadeInRight">
                                    <div class="recentpostimg">
                                        <Link to="">
                                            <img src="/hair-growth.png" alt="hair growth" class="img-fluid" />
                                        </Link>
                                    </div>
                                    <div class="recentpostcontent">
                                        <h5>
                                            <Link to="">Best Hair Growth Supplements In India</Link>
                                        </h5>
                                        <span><strong>Posted on</strong>: 30 May, 2020</span>
                                    </div>
                                </div>
                                <div class="recentpost wow animate__animated animate__fadeInRight">
                                    <div class="recentpostimg">
                                        <Link to="">
                                            <img src="/hair-growth.png" alt="hair growth" class="img-fluid" />
                                        </Link>
                                    </div>
                                    <div class="recentpostcontent">
                                        <h5>
                                            <Link to="">Best Hair Growth Supplements In India</Link>
                                        </h5>
                                        <span><strong>Posted on</strong>: 30 May, 2020</span>
                                    </div>
                                </div>
                                <div class="recentpost wow animate__animated animate__fadeInRight">
                                    <div class="recentpostimg">
                                        <Link to="">
                                            <img src="hair-growth.png" alt="hair growth" class="img-fluid" />
                                        </Link>
                                    </div>
                                    <div class="recentpostcontent">
                                        <h5>
                                            <Link to="">Best Hair Growth Supplements In India</Link>
                                        </h5>
                                        <span><strong>Posted on</strong>: 30 May, 2020</span>
                                    </div>
                                </div>
                                <div class="recentpost wow animate__animated animate__fadeInRight">
                                    <div class="recentpostimg">
                                        <Link to="">
                                            <img src="hair-growth.png" alt="hair growth" class="img-fluid" />
                                        </Link>
                                    </div>
                                    <div class="recentpostcontent">
                                        <h5>
                                            <Link to="">Best Hair Growth Supplements In India</Link>
                                        </h5>
                                        <span><strong>Posted on</strong>: 30 May, 2020</span>
                                    </div>
                                </div>
                                <div class="recentpost wow animate__animated animate__fadeInRight">
                                    <div class="recentpostimg">
                                        <Link to="">
                                            <img src="hair-growth.png" alt="hair growth" class="img-fluid" />
                                        </Link>
                                    </div>
                                    <div class="recentpostcontent">
                                        <h5>
                                            <Link to="">Best Hair Growth Supplements In India</Link>
                                        </h5>
                                        <span><strong>Posted on</strong>: 30 May, 2020</span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            </div>
          </div>
        ) : null}

        {/* hindi section */}
        {lang === "hi" ? (
          <div className="container">
            <div className="row">
              <div class="col-12">
                <h4 class="wow animate__animated animate__fadeInLeft">
                  शूगर और किसी भी तरह की शारीरिक कमजोरी को खत्म कर इम्यूनिटी भी
                  बढ़ाता है यह देसी ईलाज
                </h4>
                {/* <p class="wow postedtime animate__animated animate__fadeInRight">Posted at 06:33PM in shraman-blogs by admin</p> */}
              </div>
              <div class="col-md-12">
                <div class="leftsection">
                  <div class="post-img position-relative wow animate__animated animate__fadeInLeft animate__delay-1s">
                    <div
                      id="carouselExampleIndicators"
                      class="carousel slide"
                      data-ride="carousel"
                    >
                      {/* <ol class="carousel-indicators">
                                            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                        </ol> */}
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <img
                            class="d-block w-100"
                            src="/sugar-hindi.jpg"
                            alt="ayurvedic"
                          />
                        </div>
                        {/* <div class="carousel-item">
                                                <img class="d-block w-100" src="/hair-growth.png" alt="ayurvedic" />
                                            </div>
                                            <div class="carousel-item">
                                                <img class="d-block w-100" src="/ayurvedic.jpg" alt="ayurvedic" />
                                            </div> */}
                      </div>
                      <a
                        class="carousel-control-prev"
                        href="#carouselExampleIndicators"
                        role="button"
                        data-slide="prev"
                      >
                        <span
                          class="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="sr-only">Previous</span>
                      </a>
                      <a
                        class="carousel-control-next"
                        href="#carouselExampleIndicators"
                        role="button"
                        data-slide="next"
                      >
                        <span
                          class="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="sr-only">Next</span>
                      </a>
                    </div>
                  </div>
                  <div class="content-section mt-4 wow animate__animated animate__fadeInUp animate__delay-2s">
                    <p>
                      आज जहां पूरी दुनिया में इम्यूनिटी एक बहुत बड़ा मुद्दा बन
                      चुका है। उसी तरह महिलाओं और पुरुषों में शूगर और शारीरिक
                      कमजोरी भी आज के युग की गंभीर बीमारियाँ हैं।
                    </p>
                    <p>
                      <strong>क्या होती है डायबीटीज ?</strong> आज पुरी दुनिया की
                      एक तिहाई से अधिक आबादी शूगर की बीमारी से परेशान है। जब
                      शरीर के पेनीक्रियाज में इंसुलिन का बहाव कम हो जाने के कारण
                      खून में ग्लूकोज की मात्रा सामान्य से अधिक हो जाती है तो इस
                      स्थिति को डायबीटीज/शूगर कहा जाता है।
                    </p>
                    <p>
                      <strong>क्या होती है शारीरिक कमजोरी ? </strong> जब कोई
                      व्यक्ति या महिला अपने पति/पत्नी/ सहेली/दोस्त को शारीरिक
                      रूप से संतुष्ट नहीं कर पाते है तो उसे मर्दाना / शारीरिक
                      कमजोरी कहा जाता है। मर्दाना कमजोरी कई तरह की होती है जैसे
                      कि इच्छा की कमी होना, नील शुक्राणु, ढीलापन, समय कम लगना,
                      धांत, अंगों के विकास में कमी, वजन न बढऩा, जोड़ों का दर्द,
                      मोटापा। इसी तरह महिलाओं में भी कई तरह की शारीरिक कमजोरियां
                      पाई जाती है जैसे कि उनके अंगों के विकास की कमी, लोकोरिया,
                      औरतों के गुप्त रोग आदि।
                    </p>
                    <p>
                      <strong>इन बीमारियों के मुख्य कारण हैं -</strong> गलत
                      खान-पान, तनाव, डिप्रेशन, आज कल का लाईफ स्टाईल, बॉडी मास
                      इंडेक्स का जरूरत से कम या ज्यादा होना, शारीरिक कसरत न
                      करना, जरूरत से ज्यादा भोजन करना, तंग कपड़े, कमजोर पाचन
                      तंत्र, बीपी, मोटापा, अंग्रेजी दवाएं, नशा आदि होते हैं। इन
                      कारणों के कारण शरीर का पाचन तंत्र, पेनीक्रियाज, खून की
                      नाड़ीयां, नसें कमजोर हो जाती हैं या उनमें ब्लॉकेज आ जाती
                      है और तब हम इन बीमारियों के शिकार हो जाते हैं।
                    </p>
                    <p>
                      <strong>
                        कितने गंभीर होते हैं इन बीमारियों के नतीजे ?{" "}
                      </strong>{" "}
                      इन बीमारियों के नतीजे भी बहुत गंभीर होते हैं, जैसे कि शूगर
                      रोगी को लगी चोट जल्दी से नहीं भर पाती और खाने-पीने का भी
                      बहुत ध्यान रखना पड़ता है। शूगर रोगी को मर्दाना कमजोरी भी
                      हो जाती है। इन कारणों से रोगी का जीवन दुखो और तकलीफो में
                      निकलता है। इसी तरह मर्दाना कमजोरी वाला व्यक्ति अपनी पत्नी
                      / प्रेमिका के आगे बार-बार शर्मिंदा होता है। मर्दाना कमजोरी
                      से पीडि़त व्यक्ति को शादी करने से भी घबराहट होती है। अगर
                      यदि वह विवाहित है तो उसके वैवाहिक जीवन में दरार आ सकती है।
                      इसलिए हमें समय रहते इन बीमारियों का इलाज करवा लेना चाहिए।
                      शूगर से बचाव के लिए लोग इंसुलिन के इंजेक्शन और अंग्रेजी
                      दवा का सेवन करते हैंं। ठीक उसी तरह मर्दाना कमजोरी से बचने
                      के लिए कई तरह की अंग्रेजी दवाईयां/स्प्रै का इस्तेमाल करते
                      हैं जो एक समय तक के लिए इन समस्याओं का हल तो निकाल देता है
                      लेकिन या तो उनकी आदत पड़ जाती है या उनके गंभीर साईड इफेक्ट
                      शरीर को भुगतने पड़ते हैं।
                    </p>
                    <p>
                      <strong>क्या है इन बीमारियों का इलाज -</strong> अगर आपको
                      मर्दाना कमजोरी, शूगर, वजन न बढऩा, मोटापा, गठिया, जोड़ों का
                      दर्द, नीला शुक्राणु, स्वपन दोष और इसी तरह ही औरतों के
                      गुप्त रोग जैसे कि लोकोरिया, स्तन का आकार न बढऩा, इच्छा की
                      कमी जैसी कोई भी बीमारी है तो अब आपके पति, पत्नी, सहेली को
                      शिकायत का मौका नहीं मिलेगा क्योंकि जालंधर पंजाब स्थित
                      श्रमण हेल्थकेयर ने अपने कई वर्षों की खोज और हजारों रोगियों
                      का इलाज करने के अनुभव के साथ इन सभी बीमारियों के लिए माहिर
                      डाक्टरों की देख-रेख में ऐसी आयुर्वेदिक दवाईयां बनाई है जो
                      इन सभी बीमारियों को जड़ से खत्म कर देंगी और शरीर की रोगों
                      प्रतिरोधक क्षमता को भी बढ़ाएंगी और साथ ही इसका कोई साइड
                      इफेक्ट भी नहीं है। श्रमण हेल्थकेयर यह दावा भी करते हैं कि
                      अगर दवा असर नहीं करती तो पैसे वापिस। यह दुनिया के किसी भी
                      कोने में दवा भेज सकते हैं।
                    </p>
                    <p>
                      <strong>नोट- </strong> शूगर की दवाई 10,000 / - रुपये महीने
                      की कीमत मे लेकर बल्ड शूगर से राहत पाएं और 10,000 / - रुपये
                      की दवा के साथ 4 से 7 किलो महीने में वजन कम या बढ़ा सकते
                      हैं या जोड़ों के दर्द से हमेशा के लिए राहत पा सकते हो। ये
                      सभी दवाएं शुद्ध भस्मों से बनाई गई हैं। मर्दाना कमजोरी या
                      किसी और कमजोरी के लिए दवा भारत मे मंगवाने लेने के लिए इस
                      नंबर पर +91 98728-13954 और भारत से बाहर दवाई मंगवाने के
                      लिए +91 93177-70773 पर काल करें। अधिक जानकारी के लिए इनकी
                      वेबसाइट पढ़ें या कमजोरी संबंधी वीडियो देखने के लिए इस लिंक
                      पर क्लिक करें।
                    </p>
                  </div>
                </div>
                {/* <div class="importanttags">
                                <ul>
                                    <li class="wow animate__animated animate__fadeInUp animate__delay-3s">
                                        Health Care
                                    </li>
                                    <li class="wow animate__animated animate__fadeInUp animate__delay-3s">
                                        Physical Illness
                                    </li>
                                    <li class="wow animate__animated animate__fadeInUp animate__delay-3s">
                                        Blood Sugar
                                    </li>
                                    <li class="wow animate__animated animate__fadeInUp animate__delay-3s">
                                        Ayurvedi Medicine
                                    </li>
                                    <li class="wow animate__animated animate__fadeInUp animate__delay-3s">
                                        Hair Growth
                                    </li>
                                    <li class="wow animate__animated animate__fadeInUp animate__delay-3s">
                                        Joint Problem
                                    </li>
                                    <li class="wow animate__animated animate__fadeInUp animate__delay-3s">
                                        Weight Loss
                                    </li>
                                </ul>
                            </div> */}
                {/* <div class="next-previous mt-5">
                                <button class="btn btnprevious bglight">Previous</button>
                                <button class="btn btnnext bg-dark text-white">Next</button>
                            </div> */}
              </div>
              {/* <div class="col-md-4">
                            <div class="rightsection">
                                <h4 class="mb-2">Recent Post</h4>
                                <div class="recentpost wow animate__animated animate__fadeInRight">
                                    <div class="recentpostimg">
                                        <Link to="">
                                            <img src="/hair-growth.png" alt="hair growth" class="img-fluid" />
                                        </Link>
                                    </div>
                                    <div class="recentpostcontent">
                                        <h5>
                                            <Link to="">Best Hair Growth Supplements In India</Link>
                                        </h5>
                                        <span><strong>Posted on</strong>: 30 May, 2020</span>
                                    </div>
                                </div>
                                <div class="recentpost wow animate__animated animate__fadeInRight">
                                    <div class="recentpostimg">
                                        <Link to="">
                                            <img src="/hair-growth.png" alt="hair growth" class="img-fluid" />
                                        </Link>
                                    </div>
                                    <div class="recentpostcontent">
                                        <h5>
                                            <Link to="">Best Hair Growth Supplements In India</Link>
                                        </h5>
                                        <span><strong>Posted on</strong>: 30 May, 2020</span>
                                    </div>
                                </div>
                                <div class="recentpost wow animate__animated animate__fadeInRight">
                                    <div class="recentpostimg">
                                        <Link to="">
                                            <img src="hair-growth.png" alt="hair growth" class="img-fluid" />
                                        </Link>
                                    </div>
                                    <div class="recentpostcontent">
                                        <h5>
                                            <Link to="">Best Hair Growth Supplements In India</Link>
                                        </h5>
                                        <span><strong>Posted on</strong>: 30 May, 2020</span>
                                    </div>
                                </div>
                                <div class="recentpost wow animate__animated animate__fadeInRight">
                                    <div class="recentpostimg">
                                        <Link to="">
                                            <img src="hair-growth.png" alt="hair growth" class="img-fluid" />
                                        </Link>
                                    </div>
                                    <div class="recentpostcontent">
                                        <h5>
                                            <Link to="">Best Hair Growth Supplements In India</Link>
                                        </h5>
                                        <span><strong>Posted on</strong>: 30 May, 2020</span>
                                    </div>
                                </div>
                                <div class="recentpost wow animate__animated animate__fadeInRight">
                                    <div class="recentpostimg">
                                        <Link to="">
                                            <img src="hair-growth.png" alt="hair growth" class="img-fluid" />
                                        </Link>
                                    </div>
                                    <div class="recentpostcontent">
                                        <h5>
                                            <Link to="">Best Hair Growth Supplements In India</Link>
                                        </h5>
                                        <span><strong>Posted on</strong>: 30 May, 2020</span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            </div>
          </div>
        ) : null}
      </div>
      <Footer lang={lang} />
    </div>
  );
}
