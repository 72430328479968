import React, { useState, useEffect } from "react";
import { BASE_URL } from "../common/config";

export default function About(props) {

  const [data, setData] = useState({});

  useEffect(fetchAbout, []);

  function fetchAbout() {
    fetch(BASE_URL + 'about')
      .then(res => res.json())
      .then((response) => {
        if (response.response) {
          setData(response.data[0]);
        }
      })
      .catch(() => { })
  }


  return (
    <section className="aboutsection">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-12 animate__animated animate__fadeInLeft">
            <img src="Ayurveda.jpg" alt="about us" className="img-fluid" />
          </div>
          <div className="col-sm-6 col-12 animate__animated animate__fadeInRight">
            {props.lang === "en" ? (
              <>
                <h4>{data.englishTitle}</h4>
                <p>
                  {data.englishDescription}
                </p>
              </>
            ) : null}{" "}
            {props.lang === "hi" ? (
              <>
                <h4>{data.hindiTitle}</h4>
                <p>
                  {data.hindiDescription}
                </p>
              </>
            ) : null}{" "}
            {props.lang === "pb" ? (
              <>
                <h4>{data.punjabiTitle}</h4>
                <p>
                  <p>
                    {
                      data.punjabiDescription
                    }
                  </p>
                </p>
              </>
            ) : null}{" "}
          </div>
        </div>
      </div>
    </section>
  );
}
