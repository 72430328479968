import React from "react";
import "./phoneicon.css";

export default function Footer(props) {
  return (
    <footer className="footer py-5">
      <div className="container">
        <div className="row">
          <div className="col-sm-4  d-none d-sm-block d-md-block d-lg-block">
            {props.lang === "en" ? <h5>About Us</h5> : null}
            {props.lang === "hi" ? <h5>हमारे बारे में</h5> : null}
            {props.lang === "pb" ? <h5>ਸਾਡੇ ਬਾਰੇ</h5> : null}
            {props.lang === "en" ? (
              <p>
                We are specialized and highly experienced in treating above
                listed problems. This is the reason we just take those patients
                who come for the said health problems because we do not make
                fake promises. We believe in truth and providing quality
                treatment so that our satisfied patients could live a healthy
                life and recommend us.
              </p>
            ) : null}
            {props.lang === "hi" ? (
              <p>
                श्रमण हेल्थ केयर क्लिनिक बहुत ही पुराना और एक जाना माना क्लीनिक
                है |हमारे यहां तजुर्बेकार डॉक्टरों की देखरेख में दवाइयां तैयार
                की जाती है|यहां के डॉक्टर बहुत सी समस्याओं के लिए माहिर है जैसे
                की सेक्स समस्या, वजन बढ़ाने या वजन कम करने की आदि | हमारे यहां
                बहुमूल्य जड़ी बूटियों से निर्मित दवाइयां तैयार की जाती हैं जिससे
                कि आपको आपकी समस्याओं से जल्द से जल्द छुटकारा मिलेगा और आप
                तंदुरुस्त और सेहतमंद जिंदगी को जी सकेंगे| हम दुनिया में कहीं भी
                दवाइयां निर्यात कर सकते हैं|
              </p>
            ) : null}
            {props.lang === "pb" ? (
              <p>
                ਸਰਮਨ ਹੈਲਥ ਕੇਅਰ ਕਲਿਨਿਕ ਬਹੁਤ ਹੀ ਪੁਰਾਣਾ ਅਤੇ ਇੱਕ ਜਾਣਿਆ ਜਾਂਦਾ ਕਲੀਨਿਕ
                ਹੈ | ਇੱਥੇ ਸਾਡੇ ਤਜੁਰਬੇਕਾਰ ਡਾਕਟਰਾਂ ਦੀ ਦੇਖ ਰੇਖ ਵਿੱਚ ਦਵਾਈਆਂ ਤਿਆਰ
                ਕੀਤੀਆਂ ਜਾਂਦੀਆਂ ਹਨ | ਇੱਥੇ ਦੇ ਡਾਕਟਰ ਬਹੁਤ ਸਾਰੀਆਂ ਸਮੱਸਿਆਵਾਂ ਜਿਵੇਂ ਕਿ
                ਸੈਕਸ ਸਮੱਸਿਆਵਾਂ, ਭਾਰ ਵਧਾਉਣਾ ਜਾਂ ਘਟਾਉਣਾ ਆਦਿ ਵਿੱਚ ਮਾਹਿਰ ਹਨ | ਅਸੀਂ
                ਬਹੁਤ ਹੀ ਕੀਮਤੀ ਭਸਮਾਂ ਦੇ ਨਾਲ ਦਵਾਈਆਂ ਤਿਆਰ ਕਰਦੇ ਹਨ ਜਿਸ ਨਾਲ ਤੁਹਾਨੂੰ
                ਜਲਦ ਤੋਂ ਜਲਦ ਆਰਾਮ ਮਿਲਦਾ ਹੈਂ| ਅਸੀਂ ਦੁਨੀਆਂ ਵਿੱਚ ਕਿਤੇ ਵੀ ਦਵਾਈਆਂ ਭੇਜ
                ਸੱਕਦੇ ਹਾਂ
              </p>
            ) : null}
          </div>
          {/* <div className="col-sm-4 animate__animated animate__fadeInDown d-none d-sm-block d-md-block d-lg-block">
            {
                props.lang === "en" ? <h5>Recent Post</h5> : null
            }
            {
                props.lang === "hi" ? <h5>हाल की पोस्ट</h5> : null
            }
            {
                props.lang === "pb" ? <h5>ਹਾਲੀਆ ਪੋਸਟ</h5> : null
            }
            <ul>
              <li>
                {props.lang === "en" ? (
                  <Link to="">Don't be Frustated in Bed</Link>
                ) : null}
                {props.lang === "hi" ? (
                  <Link to="">बिस्तर में निराश मत हो</Link>
                ) : null}
                {props.lang === "pb" ? (
                  <Link to="">ਬਿਸਤਰੇ ਵਿੱਚ ਨਿਰਾਸ਼ ਨਾ ਹੋਵੋ</Link>
                ) : null}
              </li>
              <li>
                {props.lang === "en" ? (
                  <Link to="">Best Ayurvedic Medicine For Sex</Link>
                ) : null}
                {props.lang === "hi" ? (
                  <Link to="">सेक्स के लिए सर्वश्रेष्ठ आयुर्वेदिक दवा</Link>
                ) : null}
                {props.lang === "pb" ? (
                  <Link to="">ਸੈਕਸ ਲਈ ਵਧੀਆ ਆਯੁਰਵੈਦਿਕ ਮੈਡੀਕ</Link>
                ) : null}
              </li>
              <li>
                {props.lang === "en" ? (
                  <Link to="">Home Remedies For Premature Ejaculation</Link>
                ) : null}
                {props.lang === "hi" ? (
                  <Link to="">शीघ्रपतन का घरेलू इलाज</Link>
                ) : null}
                {props.lang === "pb" ? (
                  <Link to="">ਅਚਨਚੇਤੀ ਪਖਾਨੇ ਲਈ ਘਰ ਦੇ ਉਪਾਅ</Link>
                ) : null}
              </li>
              <li>
                {props.lang === "en" ? (
                  <Link to="">Best Hair Growth Supplements In India</Link>
                ) : null}
                {props.lang === "hi" ? (
                  <Link to="">भारत में सर्वश्रेष्ठ बाल विकास की आपूर्ति</Link>
                ) : null}
                {props.lang === "pb" ? (
                  <Link to="">ਭਾਰਤ ਵਿਚ ਵਧੀਆ ਬੇਵੱਸ ਵਾਧਾ ਦੀਆਂ ਯੋਜਨਾਵਾਂ</Link>
                ) : null}
              </li>
            </ul>
          </div> */}
          <div className="col-sm-4 animate__animated animate__fadeInDown">
            {
                props.lang === "en" ? <h5>Find Us</h5> : null
            }
            {
                props.lang === "hi" ? <h5>हमारा पता</h5> : null
            }
            {
                props.lang === "pb" ? <h5>ਸਾਡਾ ਪਤਾ</h5> : null
            }
            {
                props.lang === "en" ? <address>235, Monika Tower, Milap Chownk, Jalandhar (Punjab) India.</address> : null 
            }
            {
                props.lang === "hi" ? <address>235, मोनिका टॉवर, मिलाप चौंक, जालन्धर (पंजाब) भारत। </address> : null 
            }
            {
                props.lang === "pb" ? <address>235, ਮੋਨਿਕਾ ਟਾਵਰ, ਮਿਲਾਪ ਚੌਂਕ, ਜਲੰਧਰ (ਪੰਜਾਬ) ਇੰਡੀਆ</address> : null 
            }
            <p className="mb-0">
              Phone:+91 98728-13954 , +91 93177-70773, 0181 4153002{" "}
            </p>
            <p className="mb-0">Email: shramanhealthcare@gmail.com</p>
            <p className="mb-0">web: www.shramanhealthcare.com</p>
          </div>
          <div className="col-12 animate__animated animate__fadeInUp">
            <div className="socialmedia mt-4">
              <div className="socio-icon">
                <ul className="mt-2 mb-0">
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.youtube.com/channel/UCYnwdA8P3npDvVrvSC5D7xQ?view_as=subscriber"
                    >
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/shramancare/"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-5 animate__animated animate__fadeInLeft">
            <div className="text-center">
              {/*<p className="mb-0">Shraman Healthcare 2018 - 2020 &copy; All Rights Reserved</p>*/}
              <p className="mb-0">Shraman Healthcare &nbsp; 2018 © All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="callinfo">
        <a
          href="https://api.whatsapp.com/send?phone=919317770773"
          target="_blank"
          rel="noopener noreferrer"
          className="floatright"
        >
          <i className="fa fa-whatsapp my-float"></i>
        </a>
      </div>
      <a href="tel:+919872813954" className="fancybox">
        <div
          className="coccoc-alo-phone coccoc-alo-green coccoc-alo-show iconright"
          id="coccoc-alo-phoneIcon"
        >
          <div className="coccoc-alo-ph-circle"></div>
          <div className="coccoc-alo-ph-circle-fill"></div>
          <div className="coccoc-alo-ph-img-circle bgred"></div>
        </div>
      </a>
    </footer>
  );
}
