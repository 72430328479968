import React, { useEffect } from "react";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-171309197-1');

export default function BreastEnlargement(props) {
  let { breatEnlargement } = props.data
  useEffect(() => {
    ReactGA.pageview(props.location.pathname + " " + props.lang);
    window.scrollTo(0, 0);
  }, [props]);

  return (
    <div className="treatmentContent">
      {props.lang === "en" ? (
        <>
          <h4>{breatEnlargement.englishTitle}</h4>
          <div className="mt-4">
            {
              breatEnlargement.url ? (
                <iframe
                  title="lexity in penis"
                  style={{ width: "100%" }}
                  height="315"
                  src={breatEnlargement.url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullscreen
                ></iframe>
              ) : null
            }
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: breatEnlargement.englishDescription }}
          />
        </>
      ) : null}

      {props.lang === "pb" ? (
        <>
          <h4>{breatEnlargement.punjabiTitle}</h4>
          <div className="mt-4">
            {
              breatEnlargement.url ? (
                <iframe
                  title="lexity in penis"
                  style={{ width: "100%" }}
                  height="315"
                  src={breatEnlargement.url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullscreen
                ></iframe>
              ) : null
            }
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: breatEnlargement.punjabiDescription }}
          />
        </>
      ) : null}

      {props.lang === "hi" ? (
        <>
          <h4>{breatEnlargement.hindiTitle}</h4>
          <div className="mt-4">
            {
              breatEnlargement.url ? (
                <iframe
                  title="lexity in penis"
                  style={{ width: "100%" }}
                  height="315"
                  src={breatEnlargement.url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullscreen
                ></iframe>
              ) : null
            }
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: breatEnlargement.hindiDescription }}
          />ƒ
        </>
      ) : null}
    </div>
  );
}
