import React from "react";

export default function TreatmentBreadcrumbs(props) {
  return (
    <div
      id="siteContent"
      className="page-heading  animate__animated animate__fadeInDown"
      style={{ backgroundImage: "url(../treatment.jpg)" }}
    >
      <div className="container">
        <div className="row">
          {
              props.lang === "en" ? 
            <div className="col-sm-12">
              <h2>Treatments</h2>
            </div> : null
          }
          {
              props.lang === "hi" ? 
            <div className="col-sm-12">
              <h2>ईलाज</h2>
            </div> : null
          }
          {
              props.lang === "pb" ? 
            <div className="col-sm-12">
              <h2>ਇਲਾਜ</h2>
            </div> : null
          }
        </div>
      </div>
    </div>
  );
}
