import React, { useState, useEffect } from "react";
import Header from "./Homepage/Header";
import Footer from "./Homepage/Footer";
import ReactGA from "react-ga";

ReactGA.initialize("UA-171309197-1");

export default function RefundPolicy(props) {
  useEffect(() => {
    // console.log(props)
    window.scrollTo(0, 0);
  }, []);

  let [lang, setLang] = useState("en");
  // const [comp, updateComponent] = useState(null);

  useEffect(() => {
    lang = localStorage.getItem("lang");
    ReactGA.pageview(props.match.url + " " + lang);
    setLang(lang);
  }, [lang]);

  function changeLang(e) {
    localStorage.setItem("lang", e.target.value);
    setLang(e.target.value);
  }

  return (
    <div className="aboutus-section">
      <Header {...props} changeLang={changeLang} lang={lang} />
      <div
        id="siteContent"
        className="page-heading  animate__animated animate__fadeInDown"
        style={{ backgroundImage: "url(refund-policy.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {
                lang === "en" ? (<h2>Refund Policy</h2>) : null
              }
              {
                lang === "hi" ? (<h2>मनी बैक गारंटी</h2>) : null
              }
              {
                lang === "pb" ? (<h2>ਮਨੀ ਬੈੱਕ ਗਰੰਟੀ</h2>) : null
              }
            </div>
          </div>
        </div>
      </div>
      <div className="refund-policy my-5">
        <div className="container">
          {
            lang === "en" ? (
              <div className="row">
            <div className="col-md-8  animate__animated animate__fadeInDown">
              <h1>Refund Policy is valid only for those customers who have</h1>
              <ol>
                <li>Taken medicine for not less than 30 days.</li>
                <li>
                  Opted for medicine of ‘Fat Loose’,Breast Enlargement, have to
                  inform within the time span of 30 days about the recovery. On
                  31st day return policy scheme is not valid.<sup>**</sup>
                </li>
                <li>
                  Taken medicine for problems like Sex Weakness, Early
                  Discharge, Weight Gain have to inform within 10 days about the
                  medicine. on 11th day cashback policy isn’t valid.{" "}
                  <sup>**</sup>
                </li>
                <li>
                  Cashback policy is not valid for problems like Low Sperm
                  Count, Penis Size not Increasing because these may take some
                  time to cure. Patients can either get the changed medicine or
                  medicine for another 15 days will be given after the
                  completion of the course.<sup>**</sup>
                </li>
              </ol>
              <p>
                <strong>Important Note:</strong>{" "}
                <strong>
                  **Patients informing within 10 days can either get the
                  medication change or can get the money back
                </strong>
                . But, in the refunded money charges of Rs.1000/- and courier
                charges will be deducted.
              </p>
            </div>
            <div className="col-sm-4 col-12">
              <div className="refundimg  animate__animated animate__fadeInDown">
                <img
                  src="refundpolicy.jpg"
                  className="img-fluid"
                  alt="refund policy"
                />
              </div>
            </div>
          </div>
            ) : null
          }

          {/* hindi section goes here */}

          {
            lang === "hi" ? (
              <div className="row">
            <div className="col-md-8  animate__animated animate__fadeInDown">
              <h1>मनी बैक गारंटी </h1>
              <ol>
                <li>मनी बैक गारंटी केवल 1 महीने के कोर्स मंगवाने वाले पर लागू होगी|</li>
                <li>
                मोटापा कम करने वाले  या  स्तनों के आकार बढ़ाएं वाले मरीज को 30 दिन के अंदर बताना होगा कि उसे दवाई से असर पड़ रहा है या नहीं | 31 वें दिन से कैशबैक गरंटी नहीं होगी|<sup>**</sup>
                </li>
                {/* <li>
                  Taken medicine for problems like Sex Weakness, Early
                  Discharge, Weight Gain have to inform within 10 days about the
                  medicine. on 11th day cashback policy isn’t valid.{" "}
                  <sup>**</sup>
                </li> */}
                <li>
                निल शुक्राणु, धात या   लिंग के आकार को बड़ा करने वाले मरीजों के लिए कैशबैक गारंटी नहीं होगी क्योंकि इन बीमारियों को कुछ ज्यादा समय भी लग सकता है| इन बीमारियों का कोर्स करने वाले पेशेंट को दवाई बदलकर दी जा सकती है तथा पूरा कम कोर्स खत्म होने के बाद 15 दिन की अतिरिक्त दवाई दी जाएगी |<sup>**</sup>
                </li>
                <li>
                सेक्स कमजोरी जिसमें शीघ्रपतन, ढीलापन , लिंग का खड़ा ना होना तथा वजन बढ़ाने वाले मरीजों को दवाई खाने के 10 दिन के अंदर बताना होगा की दवाई उन्हें शूट कर रही है या नहीं 11 दिन के बाद बताना कैशबैक गरंटी नहीं होगी |
                </li>
              </ol>
              <p>
                {/* <strong>Important Note:</strong>{" "} */}
                <strong>
                **10 दिन के अंदर बताने वाले मरीज को उसकी इच्छा अनुसार या तो मेडिसिन बदल दी जा सकती है या  पैसे वापस लिए जा सकते हैं पैसे वापस लेने की प्रक्रिया में ₹1000 तथा दवाई भेजने का खर्च काटा जाएगा ली हुई राशि में से |
                </strong>
                
              </p>
            </div>
            <div className="col-sm-4 col-12">
              <div className="refundimg  animate__animated animate__fadeInDown">
                <img
                  src="refundpolicy.jpg"
                  className="img-fluid"
                  alt="refund policy"
                />
              </div>
            </div>
          </div>
            ) : null
          }

    {/* punjabi content */}

    {
            lang === "pb" ? (
              <div className="row">
            <div className="col-md-8  animate__animated animate__fadeInDown">
              <h1>ਮਨੀ ਬੈੱਕ ਗਰੰਟੀ </h1>
              <ol>
                <li>ਮਨੀ ਬੈੱਕ ਗਰੰਟੀ ਕੇਵਲ 1 ਮਹੀਨੇ ਦੇ ਕੋਰਸ ਮੰਗਵਾਣ ਵਾਲੇ ਮਰੀਜਾਂ ਤੇ ਲਾਗੂ ਹੋਵੇਗਾ | </li>
                <li>
                ਮੋਟਾਪਾ ਘਟਾਣ ਵਾਲੇ ਮਰੀਜਾਂ  ਜਾਂ ਛਾਤੀ ਦੇ ਆਕਾਰ ਨੂੰ ਵੱਡਾ ਕਰਨ ਵਾਲੇ ਮਰੀਜਾਂ ਨੂੰ 30 ਦਿਨਾਂ ਦੇ ਅੰਦਰ ਦੱਸਣਾ ਹੋਊਗਾ, ਕਿ ਦਵਾਈ ਓਨੂੰ ਅਸਰ ਕਰ ਰਹੀ ਹੈ ਜਾਂ ਨਹੀਂ  । 31 ਵੇਂ ਦਿਨ ਕੈਸ਼ ਬੈਕ ਗਾਰੰਟੀ ਨਹੀਂ ਦਿੱਤੀ ਜਾਵੇਗੀ । <sup>**</sup>
                </li>
                {/* <li>
                  Taken medicine for problems like Sex Weakness, Early
                  Discharge, Weight Gain have to inform within 10 days about the
                  medicine. on 11th day cashback policy isn’t valid.{" "}
                  <sup>**</sup>
                </li> */}
                <li>
                ਨੀਲ ਸ਼ੁਕਰਾਣੂ ਧਾਤ ਜਾਂ ਲਿੰਗ ਦੇ ਆਕਾਰ ਨੂੰ ਵੱਡਾ ਕਰਨ ਵਾਲੇ ਮਰੀਜਾਂ ਲਈ  ਕੈਸ਼ ਬੈਕ ਗਾਰੰਟੀ ਨਹੀਂ ਦਿੱਤੀ ਜਾਵੇਗੀ ।  ਕਿਉਂਕਿ ਐਨਾਂ ਬੀਮਾਰੀਆਂ ਨੂੰ ਕੁੱਜ ਜ਼ਿਆਦਾ ਸਮੇਂ ਵੀ ਲੱਗ ਸਕਦਾ ਹੈ । ਇੰਨਾ ਦਵਾਇਆ ਦਾ ਕੋਰਸ ਕਰਨ ਵਾਲੇ ਮਰੀਜਾਂ ਨੂੰ ਦਵਾਈ ਬਦਲ ਕੇ ਦੀ ਜਾ ਸਕਦੀ ਹੈ , ਅਤੇ ਪੂਰਾ ਕੋਰਸ ਖ਼ਤਮ ਹੋਣ ਤੋਂ ਬਾਅਦ 15 ਦਿਨ ਦੀ ਦਵਾਈ ਹੋਰ ਦਿੱਤੀ ਜਾਵੇਗੀ ।<sup>**</sup>
                </li>
                <li>
                ਸੈਕਸ ਕਮਜ਼ੋਰੀ ਜਿਦੇ ਵਿਚ ਸ਼ਿਘਰਪਤੰ, ਢੀਲਾਪਣ,ਲਿੰਗ ਦਾ ਖੜ੍ਹਾ ਨਾ ਹੋਣਾ ਅਤੇ ਵਜ਼ਨ ਵਧਾਉਣ ਵਾਲੇ ਮਰੀਜਾਂ ਨੂੰ ਦਵਾਈ ਖਾਣ ਦੇ 10 ਦਿਨਾਂ ਦੇ ਅੰਦਰ ਦੱਸਣਾ ਹੋਊਗਾ, ਕਿ ਦਵਾਈ ਓਨੂੰ ਅਸਰ ਕਰ ਰਹੀ ਹੈ ਜਾਂ ਨਹੀਂ  ।  11ਵੇਂ ਦਿਨ ਤੋਂ ਬਾਅਦ ਕੈਸ਼ ਬੈਕ ਗਾਰੰਟੀ ਨਹੀਂ ਦਿੱਤੀ ਜਾਵੇਗੀ । **
                </li>
              </ol>
              <p>
                {/* <strong>Important Note:</strong>{" "} */}
                <strong>
                **10 ਦਿਨ ਦੇ ਬਾਅਦ ਦੱਸਣ ਵਾਲੇ ਮਰੀਜ਼ ਨੂੰ ਓਨ੍ਨਾ ਦੀ ਇੱਛਾ ਅਨੁਸਾਰ ਜਾ ਤਾਂ ਦਵਾਈ ਬੱਦਲ ਦਿੱਤੀ ਜਾਊਗੀ  ਜਾਂ ਪੈਸੇ ਵਾਪਿਸ ਲਿੱਤੇ ਜਾ ਸਕਦੇ ਹੈ ।  ਪੈਸੇ ਵਾਪਿਸ ਲੈਣ ਦੀ ਪਰਕਿਰਿਆ ਵਿੱਚ ਰੂ 1000 ਅਤੇ ਦਵਾਈ ਭੇਜਣ ਦਾ ਖ਼ਰਚਾ ਲਿਤੇ ਹੋਏ ਪੈਸਿਆਂ ਵਿੱਚੋ ਕਟਿਆ ਜਾਏਗਾ।   
                </strong>
                
              </p>
            </div>
            <div className="col-sm-4 col-12">
              <div className="refundimg  animate__animated animate__fadeInDown">
                <img
                  src="refundpolicy.jpg"
                  className="img-fluid"
                  alt="refund policy"
                />
              </div>
            </div>
          </div>
            ) : null
          }
        </div>
      </div>
      <Footer lang={lang}/>
    </div>
  );
}
