import React, { useState, useEffect } from 'react';
import { BASE_URL, IMAGE_URL } from '../common/config';

export default function Banner(props) {
    const [loading, setLoading] = useState(false);
    const [allImages, setAllImages] = useState([{ imageOne: { english: '', hindi: '', punjabi: '' }, imageTwo: { english: '', hindi: '', punjabi: '' }, imageThree: { english: '', hindi: '', punjabi: '' }, imageFour: { english: '', hindi: '', punjabi: '' } }]);

    useEffect(fetchSliders, []);
    return (
        <section className="site-banner" id="siteContent">
            <div className="banner-section">
                {
                    loading ? (
                        <div className="col-md-12 d-flex justify-content-center">
                            <span className="mt-5">
                                <div class="loader">Loading...</div>
                            </span>
                        </div>
                    ) : (
                            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                <ol className="carousel-indicators">
                                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                                </ol>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        {props.lang === "en" ? <img className="d-block w-100" src={IMAGE_URL + allImages[0].imageOne.english} alt="ayurvedic" /> : null}
                                        {props.lang === "hi" ? <img className="d-block w-100" src={IMAGE_URL + allImages[0].imageOne.hindi} alt="ayurvedic" /> : null}
                                        {props.lang === "pb" ? <img className="d-block w-100" src={IMAGE_URL + allImages[0].imageOne.punjabi} alt="ayurvedic" /> : null}
                                    </div>
                                    <div className="carousel-item">
                                        {props.lang === "en" ? <img className="d-block w-100" src={IMAGE_URL + allImages[0].imageTwo.english} alt="ayurvedic" /> : null}
                                        {props.lang === "hi" ? <img className="d-block w-100" src={IMAGE_URL + allImages[0].imageTwo.hindi} alt="ayurvedic" /> : null}
                                        {props.lang === "pb" ? <img className="d-block w-100" src={IMAGE_URL + allImages[0].imageTwo.punjabi} alt="ayurvedic" /> : null}
                                    </div>
                                    <div className="carousel-item">
                                        {props.lang === "en" ? <img className="d-block w-100" src={IMAGE_URL + allImages[0].imageThree.english} alt="ayurvedic" /> : null}
                                        {props.lang === "hi" ? <img className="d-block w-100" src={IMAGE_URL + allImages[0].imageThree.hindi} alt="ayurvedic" /> : null}
                                        {props.lang === "pb" ? <img className="d-block w-100" src={IMAGE_URL + allImages[0].imageThree.punjabi} alt="ayurvedic" /> : null}
                                    </div>
                                    <div className="carousel-item">
                                        {props.lang === "en" ? <img className="d-block w-100" src={IMAGE_URL + allImages[0].imageFour.english} alt="ayurvedic" /> : null}
                                        {props.lang === "hi" ? <img className="d-block w-100" src={IMAGE_URL + allImages[0].imageFour.hindi} alt="ayurvedic" /> : null}
                                        {props.lang === "pb" ? <img className="d-block w-100" src={IMAGE_URL + allImages[0].imageFour.punjabi} alt="ayurvedic" /> : null}
                                    </div>
                                </div>
                            </div>
                        )
                }
            </div>
        </section>
    );
    function fetchSliders() {
        let allImages = localStorage.getItem('allImages');
        if (allImages) {
            if (JSON.parse(allImages).length > 0) {
                setAllImages(JSON.parse(allImages));
            }
            else {
                setLoading(true)
                fetch(BASE_URL + 'slider')
                    .then(res => res.json())
                    .then((response) => {
                        if (response.response) {
                            if (response.data.length === 0)
                                setAllImages([{ imageOne: { english: '', hindi: '', punjabi: '' }, imageTwo: { english: '', hindi: '', punjabi: '' }, imageThree: { english: '', hindi: '', punjabi: '' }, imageFour: { english: '', hindi: '', punjabi: '' } }])
                            else {
                                localStorage.setItem('allImages', JSON.stringify(response.data));
                                setAllImages(response.data);
                            }
                        }
                    })
                    .catch((error) => {
                        // toast.error("Something went wrong, please try agian later");
                    })
                    .finally(_ => setLoading(false))
            }
        }
        else {
            setLoading(true)
            fetch(BASE_URL + 'slider')
                .then(res => res.json())
                .then((response) => {
                    if (response.response) {
                        if (response.data.length === 0)
                            setAllImages([{ imageOne: { english: '', hindi: '', punjabi: '' }, imageTwo: { english: '', hindi: '', punjabi: '' }, imageThree: { english: '', hindi: '', punjabi: '' }, imageFour: { english: '', hindi: '', punjabi: '' } }])
                        else {
                            localStorage.setItem('allImages', JSON.stringify(response.data));
                            setAllImages(response.data);
                        }
                    }
                })
                .catch((error) => {
                    // toast.error("Something went wrong, please try agian later");
                })
                .finally(_ => setLoading(false))
        }
    }
}