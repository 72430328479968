import React, {useEffect} from "react";

export default function SexWeakness(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props]);
  return (
    <div className="treatmentContent">
      <h4>SEX WEAKNESS</h4>
      <div className="mt-4">
        <iframe
          title="sex weakness"
          style={{ width: "100%" }}
          height="315"
          src="https://www.youtube.com/embed/a2wjysBSB_s"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullscreen
        ></iframe>
      </div>
      <p>
        Let’s talk about sex and different problems which are related to it.
        First of all, we all need to know that what actually sex means? Sex or
        Sexual Intercourse is basically the insertion or thrusting of penis
        usually when erect into the vagina for sexual pleasure, reproduction or
        both. It is entirely a natural process and the feeling of doing the same
        is there both in males and females. Everybody wants a good sex as it is
        important for a body as it relieves stress and helps in leading a happy
        and satisfied life.
      </p>
      <p>
        But, as we all know that there are many people who are suffering from
        different types of sex problems and are ashamed of sharing it with
        others thinking that they will become a laughing element in front of
        everybody. Due to lack of awareness people do not know where to go and
        what to do. But now you all need not worry as I am here to tell you
        about the different problems that people especially males suffer
        belonging to different age groups. We assure you that we provide pure
        ayurvedic treatments which do not involve any chemicals that can cause
        any sort of harm to the body.
      </p>
      <p>
        The very basic sex problems that people suffer from are early discharge
        (lesser time or premature ejaculation), Thinness of sperm, nightfall,
        low sperms count, small penis, lack of desire of having sex, Dhat
        problem, etc. When one is not able to satisfy his/her partner or if you
        are not leading a satisfied life then what is the purpose of your hard
        earned money. The <strong>main causes</strong> of such problems are
        tension, depression, our present lifestyle, intake of allopathic
        medicines, eating products which are sour in taste,childhood mistakes
        (masturbation), intake of drugs or alcohol, sugar, any change in body
        due to some operation low pressure of blood in penis which results into
        lesser stability of the penis etc. All these factors result in a
        weakness of the nerves thereby resulting in the thinness of the sperm
        and lesser energy in retaining the sperm within it. What will your hard
        earned money do when you are not able to satisfy your partner and lead a
        happy life? So, don’t hesitate to contact us if you are a victim of any
        problem. From our last 60 years’ experience, we have found a unique
        formula to make you able to get rid of this problem. We believe in
        giving natural treatment which involves Gold- Siver ashes, Shwetavar,
        Musilli, Baikonur, Shilajeet, Shalavdana, Ashwagandha etc. These do not
        have any side effects as they are purely ayurvedic.
      </p>
      <h5>Our courses are defined as</h5>
      <p>
        <strong>Honeymoon Course:</strong> This course is especially for
        newlyweds.
      </p>
      <ul>
        <li>It takes 1 month to recover the problem</li>
        <li>
          It Includes diamond herbs and other herbs of superlative quality.
        </li>
        <li>Safe and leaves zero side effects.</li>
      </ul>
      <p>
        also accepted on +91 98728-13954. Payment can be deposited in our bank
        account:
      </p>
      <p>
        <strong>
          Gold Course: You will see the result after the first dose.
        </strong>
      </p>
      <ul>
        <li>Takes 2 month to recover</li>
        <li>
          Includes a high-quality mixture of rare herbs which gives positive
          results.
        </li>
        <li>Safe and leaves zero side effects.</li>
      </ul>
      <p>
        <strong>Silver Course: You will see the result in 4-5 days.</strong>
      </p>
      <ul>
        <li>Takes 3 month to recover</li>
        <li>
          Includes a high-quality mixture of rare herbs which gives positive
          results.
        </li>
        <li>Safe and leaves zero side effects.</li>
      </ul>
      <h5>Payments mode for Indian Customers:</h5>
      <p>Cash On Delivery.</p>
      <p>A payment through Paytm is also accepted.</p>

      <div className="bankdetails mb-3">
        <div className="row">
          <div className="col-sm-6 col-12">
            <h5>
              Shraman Health Care ICICI BANK <br />
              A/c No. 151205000555 <br />
              IFSC Code. ICIC0001512 <br />
              Branch. Civil Lines Jalandhar <br />
            </h5>
          </div>
          <div className="col-sm-6 col-12">
            <h5>
              Punjab National Bank, <br />
              Name: Dr. Ramdev Kapoor, <br />
              Account Number: 0235002101719421 <br />
              IFSC CODE: PUNB0023500 <br />
            </h5>
          </div>
        </div>
      </div>

      <p>
        For online order call us on +91 93177-70773, +91 98728-13954,
        0181-4153002 and WhatsApp us the receipt after making payment and send
        us your address.
      </p>

      <h5>Payment mode for Foreign Customers:</h5>
      <p>
        Payments through Western Money Union, Money Gram, and Express Money with
        the name <strong>Suhel Jain</strong> are accepted.
      </p>
    </div>
  );
}
