import React from "react";
// import {Link} from 'react-router-dom';
import "./treatment.css";

export default function Treatment(props) {
  return (
    <div className="site-treatment">
      <div className="treatment-content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-12">
              {/* <h1>Treatments</h1> */}
              <div className="accordion" id="accordionExample">
                <div className="maleproblem-box mt-5">
                  {props.lang === "en" ? (
                    <span className="bg-m">1. Male Sex Problems</span>
                  ) : null}
                  {props.lang === "hi" ? (
                    <span className="bg-m">1. पुरूषों की सैक्स समस्याएं</span>
                  ) : null}
                  {props.lang === "pb" ? (
                    <span className="bg-m">1. ਪੁਰਸ਼ਾਂ ਦੀਆਂ ਸੈਕਸ ਸਮੱਸਿਆਵਾਂ</span>
                  ) : null}

                  <div className="card mb-1 mt-3" onClick={() =>
                            props.history.push("/treatment/erectile-dysfunction")
                          }>
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button
                          style={{paddingTop: "5px"}}
                          className="btn btn-link "
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          {props.lang === "en" ? "Erectile Dysfunction" : null}
                          {props.lang === "hi" ? "लिंग का ढीलापन" : null}
                          {props.lang === "pb" ? "ਲਿੰਗ ਦਾ ਢੀਲਾਪਨ" : null}
                        </button>
                        {/* <i class="fa fa-chevron-down text-white" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i> */}
                      </h2>
                    </div>
                  </div>

                  <div className="card mb-1" onClick={() =>
                            props.history.push(
                              "/treatment/premature-ejaculation"
                            )
                          }>
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          style={{paddingTop: "5px"}}
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          
                        >
                          {props.lang === "en" ? "Premature Ejaculation" : null}
                          {props.lang === "hi" ? "शीघ्रपतन" : null}
                          {props.lang === "pb" ? "ਸ਼ੀਘਰਪਤਨ" : null}
                        </button>
                        {/* <i class="fa fa-chevron-down text-white" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i> */}
                      </h2>
                    </div>
                  </div>

                  <div className="card mb-1" onClick={() =>
                            props.history.push(
                              "/treatment/lack-of-desire-in-men"
                            )
                          }>
                    <div className="card-header" id="headingThree">
                      <h2 class="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          style={{paddingTop: "5px"}}
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          
                        >
                          {props.lang === "en" ? "Low Libido" : null}
                          {props.lang === "hi"
                            ? "इच्छा की कमी"
                            : null}
                          {props.lang === "pb"
                            ? "ਇੱਛਾ ਦੀ ਕਮੀ"
                            : null}
                        </button>
                        {/* <i class="fa fa-chevron-down text-white" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i> */}
                      </h2>
                    </div>
                  </div>

                  <div className="card mb-1" onClick={() =>
                            props.history.push("/treatment/penis-enlargement")
                          }>
                    <div className="card-header" id="heading4">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          style={{paddingTop: "5px"}}
                          data-target="#collapse4"
                          aria-expanded="false"
                          aria-controls="collapse4"
                          
                        >
                          {props.lang === "en" ? "Penis Enlargement" : null}
                          {props.lang === "hi" ? "लिंग का आकार बढाएं" : null}
                          {props.lang === "pb" ? "ਲਿੰਗ ਦਾ ਆਕਾਰ ਵਧਾਓ" : null}
                        </button>
                        {/* <i class="fa fa-chevron-down text-white" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i> */}
                      </h2>
                    </div>
                  </div>

                  <div className="card mb-1" onClick={() =>
                            props.history.push("/treatment/low-sperm-count")
                          }>
                    <div className="card-header" id="heading5">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          style={{paddingTop: "5px"}}
                          data-target="#collapse5"
                          aria-expanded="false"
                          aria-controls="collapse5"
                          
                        >
                          {props.lang === "en" ? "Low Sperm Count" : null}
                          {props.lang === "hi" ? "नील शुक्राणुओं" : null}
                          {props.lang === "pb" ? "ਨੀਲ ਸ਼ੁਕਰਾਣੂ" : null}
                        </button>
                        {/* <i class="fa fa-chevron-down text-white" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i> */}
                      </h2>
                    </div>
                  </div>

                  <div className="card mb-1" onClick={() =>
                            props.history.push("/treatment/nightfall")
                          }>
                    <div className="card-header" id="heading6">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          style={{paddingTop: "5px"}}
                          data-target="#collapse6"
                          aria-expanded="false"
                          aria-controls="collapse6"
                          
                        >
                          {props.lang === "en" ? "Night Fall" : null}
                          {props.lang === "hi" ? "स्वप्न दोष" : null}
                          {props.lang === "pb" ? "ਸੰਪਨ ਦੋਸ਼" : null}
                        </button>
                        {/* <i class="fa fa-chevron-down text-white" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i> */}
                      </h2>
                    </div>
                  </div>

                  <div class="card mb-1" onClick={() =>
                            props.history.push("/treatment/sex-problem")
                          }> 
                    <div class="card-header" id="heading7">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          style={{paddingTop: "5px"}}
                          data-target="#collapse7"
                          aria-expanded="false"
                          aria-controls="collapse7"
                          
                        >
                          {props.lang === "en" ? "Dhat" : null}
                          {props.lang === "hi" ? "धांत" : null}
                          {props.lang === "pb" ? "ਧਾਂਤ" : null}
                        </button>
                        {/* <i class="fa fa-chevron-down text-white" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i> */}
                      </h2>
                    </div>
                  </div>
                  <div className="maleproblem-box mt-5">
                    <span className="bg-wi">
                      {props.lang === "en" ? "2. Weight Problems" : null}
                      {props.lang === "hi" ? "2. वजन की समस्याएं" : null}
                      {props.lang === "pb" ? "2. ਵਜ਼ਨ ਦੀਆਂ ਸਮੱਸਿਆਵਾਂ" : null}
                    </span>
                    <div class="card mb-1 mt-3" onClick={() =>
                              props.history.push("/treatment/weight-gain")
                            }>
                      <div class="card-header" id="heading8">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link collapsed"
                            style={{paddingTop: "5px"}}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse8"
                            aria-expanded="false"
                            aria-controls="collapse8"
                            
                          >
                            {props.lang === "en" ? "Weight Gain" : null}
                            {props.lang === "hi" ? "वजन बढ़ाएं" : null}
                            {props.lang === "pb" ? "ਵਜ਼ਨ ਵਧਾਓ" : null}
                          </button>
                          {/* <i class="fa fa-chevron-down text-white" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i> */}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="card mb-1" onClick={() =>
                            props.history.push("/treatment/weight-loss")
                          }>
                    <div class="card-header" id="heading9">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          style={{paddingTop: "5px"}}
                          data-target="#collapse9"
                          aria-expanded="false"
                          aria-controls="collapse9"
                          
                        >
                          {props.lang === "en" ? "Weight Loose" : null}
                          {props.lang === "hi" ? "मोटापा घटाएं" : null}
                          {props.lang === "pb" ? "ਮੋਟਾਪਾ ਘਟਾਓ" : null}
                        </button>
                        {/* <i class="fa fa-chevron-down text-white" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i> */}
                      </h2>
                    </div>
                  </div>
                  <div className="maleproblem-box mt-5">
                    <span className="bg-sp">
                      {props.lang === "en" ? "3. Sugar Problem" : null}
                      {props.lang === "hi" ? "3. शुगर समस्या " : null}
                      {props.lang === "pb" ? "3. ਸ਼ੂਗਰ ਸਮੱਸਿਆ" : null}
                    </span>
                    <div class="card mb-1 mt-3"  onClick={() =>
                              props.history.push("/treatment/sugar")
                            }>
                      <div class="card-header" id="heading10">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link collapsed"
                            style={{paddingTop: "5px"}}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse10"
                            aria-expanded="false"
                            aria-controls="collapse10"
                           
                          >
                            {props.lang === "en" ? "Diabetes" : null}
                            {props.lang === "hi" ? "डायबिटीज" : null}
                            {props.lang === "pb" ? "ਡਾਈਬੀਟੀਜ਼" : null}
                          </button>
                          {/* <i class="fa fa-chevron-down text-white" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i> */}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="maleproblem-box mt-5">
                    <span className="bg-ar">
                      {props.lang === "en" ? "4. Arthritis" : null}
                      {props.lang === "hi" ? "4. गठिया की समस्या" : null}
                      {props.lang === "pb" ? "4. ਗਠੀਆ ਦੀ ਸਮੱਸਿਆ" : null}
                    </span>
                    <div class="card mb-1 mt-3" onClick={() =>
                              props.history.push(
                                "/treatment/arthritis-and-joint"
                              )
                            }>
                      <div class="card-header" id="heading11">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link collapsed"
                            style={{paddingTop: "5px"}}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse11"
                            aria-expanded="false"
                            aria-controls="collapse11"
                            
                          >
                            {props.lang === "en" ? "Joint Pain" : null}
                            {props.lang === "hi" ? "जोड़ों का दर्द" : null}
                            {props.lang === "pb" ? "ਜੋੜਾਂ ਦਾ ਦਰਦ" : null}
                          </button>
                          {/* <i class="fa fa-chevron-down text-white" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i> */}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="maleproblem-box mt-5">
                    <span className="bg-f">
                      {props.lang === "en" ? "5. Female Sex Problems" : null}
                      {props.lang === "hi" ? "5. महिलाओं की सैक्स समस्याएं" : null}
                      {props.lang === "pb" ? "5. ਔਰਤਾਂ ਦੀਆਂ ਸੈਕਸ ਸਮੱਸਿਆਵਾਂ" : null}
                    </span>
                    <div class="card mb-1 mt-3" onClick={() =>
                              props.history.push(
                                "/treatment/lack-of-desire-in-women"
                              )
                            }>
                      <div class="card-header" id="heading12">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link collapsed"
                            style={{paddingTop: "5px"}}
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse12"
                            aria-expanded="false"
                            aria-controls="collapse12"
                            
                          >
                            {props.lang === "en" ? "Low Libido " : null}
                            {props.lang === "hi"
                              ? "इच्छा की कमी"
                              : null}
                            {props.lang === "pb"
                              ? "ਇੱਛਾ ਦੀ ਕਮੀ"
                              : null}
                          </button>
                          {/* <i class="fa fa-chevron-down text-white" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i> */}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="card mb-1" onClick={() =>
                            props.history.push("/treatment/leukorrhea")
                          }>
                    <div class="card-header" id="heading13">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          style={{paddingTop: "5px"}}
                          data-target="#collapse13"
                          aria-expanded="false"
                          aria-controls="collapse13"
                          
                        >
                          {props.lang === "en" ? "Leukorrhea" : null}
                          {props.lang === "hi" ? "ल्यूकोरिया" : null}
                          {props.lang === "pb" ? "ਲੋਕੋਰੀਆ" : null}
                        </button>
                        {/* <i class="fa fa-chevron-down text-white" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i> */}
                      </h2>
                    </div>
                  </div>
                  <div class="card mb-1" onClick={() =>
                            props.history.push("/treatment/breast-enlargement")
                          }>
                    <div class="card-header" id="heading14">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          style={{paddingTop: "5px"}}
                          data-target="#collapse14"
                          aria-expanded="false"
                          aria-controls="collapse14"
                          
                        >
                          {props.lang === "en" ? "Breast Enlargement" : null}
                          {props.lang === "hi" ? "स्तन का आकार बढ़ाएं" : null}
                          {props.lang === "pb" ? "ਛਾਤੀ ਦਾ ਆਕਾਰ ਵਧਾਓ" : null}
                        </button>
                        {/* <i class="fa fa-chevron-down text-white" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></i> */}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
