import React, { useState, useEffect } from "react";
import Header from "./Homepage/Header";
import Footer from "./Homepage/Footer";
import ReactGA from "react-ga";
import { BASE_URL } from "./common/config";

ReactGA.initialize("UA-171070900-1");

export default function AboutUs(props) {

  const [data, setData] = useState({})

  useEffect(() => {
    // console.log(props)
    window.scrollTo(0, 0);
  }, []);

  useEffect(fetchAbout, []);

  let [lang, setLang] = useState("en");
  // const [comp, updateComponent] = useState(null);

  useEffect(() => {
    lang = localStorage.getItem("lang");
    ReactGA.pageview(props.match.url + " " + lang);
    setLang(lang);
  }, [lang]);



  function changeLang(e) {
    localStorage.setItem("lang", e.target.value);
    setLang(e.target.value);
  }

  function fetchAbout() {
    fetch(BASE_URL + 'about')
      .then(res => res.json())
      .then((response) => {
        if (response.response) {
          setData(response.data[0]);
        }
      })
      .catch(() => { })
  }

  return (
    <div className="aboutussection">
      <Header {...props} changeLang={changeLang} lang={lang} />
      <div
        id="siteContent"
        className="page-heading animate__animated animate__fadeInDown"
        style={{ backgroundImage: "url(ayurved.jpg)" }}
      >
        <div className="container">
          <div className="row">
            {lang === "en" ? (
              <div className="col-sm-12">
                <h2>About Us</h2>
              </div>
            ) : null}
            {lang === "hi" ? (
              <div className="col-sm-12">
                <h2>हमारे बारे में</h2>
              </div>
            ) : null}
            {lang === "pb" ? (
              <div className="col-sm-12">
                <h2>ਸਾਡੇ ਬਾਰੇ</h2>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="about-content my-5">
        <div className="container">
          <div className="row">
            {lang === "en" ? (
              <>
                <div className="col-sm-12  animate__animated animate__fadeInDown">
                  <h1>{data.englishTitle}</h1>
                </div>
                <div className="col-sm-8 col-12 mt-4 text-justify">
                  {
                    data.englishDescription
                  }
                </div>
              </>
            ) : null}
            {lang === "hi" ? (
              <>
                <div className="col-sm-12  animate__animated animate__fadeInDown">
                  <h1>{data.hindiTitle}</h1>
                </div>
                <div className="col-sm-8 col-12 mt-4 text-justify">
                  {data.hindiDescription}
                </div>
              </>
            ) : null}

            {lang === "pb" ? (
              <>
                <div className="col-sm-12  animate__animated animate__fadeInDown">
                  <h1>{data.punjabiTitle}</h1>
                </div>
                <div className="col-sm-8 col-12 mt-4 text-justify">
                  {
                    data.punjabiDescription
                  }
                </div>
              </>
            ) : null}

            <div className="col-sm-4 col-12  animate__animated animate__fadeInDown">
              <img src="Ayurveda.jpg" alt="about us" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="mission-vision py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-12">
              <div className="contentBox mb-3 animate__animated animate__fadeInDown animate__delay-2s">
                {lang === "en" ? (
                  <div className="mv-box">
                    <h5>Mission</h5>
                    <p>
                      Our Mission is to help people to spend their marriage life
                      happily and satisfactory.
                    </p>
                  </div>
                ) : null}
                {lang === "hi" ? (
                  <div className="mv-box">
                    <h5>मिशन</h5>
                    <p>
                      हमारा मिशन लोगों को अपने वैवाहिक जीवन को खुशी और संतोषजनक
                      ढंग से बिताने में मदद करना है।
                    </p>
                  </div>
                ) : null}
                {lang === "pb" ? (
                  <div className="mv-box">
                    <h5>ਮਿਸ਼ਨ</h5>
                    <p>
                      ਸਾਡਾ ਮਿਸ਼ਨ ਲੋਕਾਂ ਦੀ ਆਪਣੀ ਵਿਆਹੁਤਾ ਜ਼ਿੰਦਗੀ ਨੂੰ ਖੁਸ਼ੀ ਅਤੇ
                      ਤਸੱਲੀਬਖਸ਼ ਜੀਵਨ ਬਿਤਾਉਣ ਵਿਚ ਮਦਦ ਕਰਨਾ ਹੈ.
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-sm-4 col-12">
              <div className="contentBox mb-3 animate__animated animate__fadeInDown animate__delay-3s">
                {lang === "en" ? (
                  <div className="mv-box">
                    <h5>Vision</h5>
                    <p>
                      To extend ourself globally and reach individually without
                      any hustle
                    </p>
                  </div>
                ) : null}
                {lang === "hi" ? (
                  <div className="mv-box">
                    <h5>विजन</h5>
                    <p>
                      विश्व स्तर पर अपने आप को विस्तारित करने के लिए और बिना
                      किसी हलचल के व्यक्तिगत रूप से पहुंचने के लिए
                    </p>
                  </div>
                ) : null}
                {lang === "pb" ? (
                  <div className="mv-box">
                    <h5>ਦਰਸ਼ਣ</h5>
                    <p>
                      ਆਪਣੇ ਆਪ ਨੂੰ ਵਿਸ਼ਵ ਪੱਧਰ ‘ਤੇ ਵਧਾਉਣ ਅਤੇ ਬਿਨਾਂ ਕਿਸੇ ਭੀੜ ਦੇ
                      ਵਿਅਕਤੀਗਤ ਤੌਰ’ ਤੇ ਪਹੁੰਚਣ ਲਈ
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-sm-4 col-12">
              <div className="contentBox mb-3 active  animate__animated animate__fadeInDown animate__delay-4s">
                {lang === "en" ? (
                  <div className="mv-box">
                    <h5>100% Customer satisfaction</h5>
                  </div>
                ) : null}
                {lang === "hi" ? (
                  <div className="mv-box">
                    <h5>100% ग्राहक संतुष्टि</h5>
                  </div>
                ) : null}
                {lang === "pb" ? (
                  <div className="mv-box">
                    <h5>100% ਗਾਹਕ ਸੰਤੁਸ਼ਟੀ</h5>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer lang={lang} />
    </div>
  );
}
