import React from "react";

export default function Testimonialvideo(props) {
  return (
    <section
      className="trustwebuild"
      style={{ backgroundImage: "url(video_parallax.jpg)" }}
    >
      <div className="container">
        {props.lang === "en" ? (
          <div className="row">
            <div className="col-sm-12 mb-5 animate__animated animate__fadeInLeft d-none">
              <h4>Shraman Healthcare : Testimonials</h4>
            </div>
            <div className="col-sm-6 col-12">
              <div className="testimonialbox mb-4 animate__animated animate__fadeInLeft">
                <div className="testimonial">
                  <img src="002522-150x150.jpg" alt="testimonial profile" />
                  <div className="rating">
                    <ul>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star text-white"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    "I am 25 years old and my weight was just 45 kgs. I had no
                    idea why my weight is so less inspite of proper intake of
                    the diet. I used to feel insecure about my looks. I must
                    have consulted numerous doctors but no change was seen Then,
                    I heard about Shraman Health Care and thought of trying it.
                    But, this proved out to be lucky for me. I consumed their
                    medicines and my weight increased upto 12 kgs in 3 months.
                    Their experiences doctors have changed my life. Now I
                    recommend them to everyone. Thankyou soo much Shraman team."
                  </p>
                  <span>Santosh Kumar (Jalandhar)</span>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-12">
              <div className="testimonialbox mb-4 animate__animated animate__fadeInLeft">
                <div className="testimonial">
                  <img src="002522-150x150.jpg" alt="testimonial profile" />
                  <div className="rating">
                    <ul>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    "I have been married for 8 years. But, I was not able to
                    satisfy my wife in bed. Inspite of getting treatments from
                    various places I could not resolve my problem. Because of
                    this my married life was disturbed. Then I read about
                    Shraman Health Care. I contacted them and they prescribed me
                    to take their medicines for atleast 3 months. I could feel
                    the change in first few doses. Now I am perfectly fine.
                    Thanku Shrama Health Care."
                  </p>
                  <span>Sh. Mohinder Singh (Canada)</span>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-12">
              <div className="testimonialbox mb-4 animate__animated animate__fadeInLeft">
                <div className="testimonial">
                  <img src="002522-150x150.jpg" alt="testimonial profile" />
                  <div className="rating">
                    <ul>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    "I am 50 years old. I am a bank employee . Since last 3
                    years I was suffering from severe joint pain. I was taking
                    completely consuming English medicines. They used to give me
                    temporary relief but my body was becoming the victim of
                    steroids. Then, one of my friend recommended me of Shraman
                    Health Care. Their doctors prepare the medicines according
                    to my problem. Thanks to them that now I am completely a
                    different person. After completion of my course I couldn't
                    feel any sort of pain and am leading a comfortable and happy
                    life."
                  </p>
                  <span>Sh. MAnjinder Singh (Ludhiana)</span>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* hindi section goes here  */}

        {props.lang === "hi" ? (
          <div className="row">
            <div className="col-sm-12 mb-5 animate__animated animate__fadeInLeft d-none">
              <h4>Shraman Healthcare : Testimonials</h4>
            </div>
            <div className="col-sm-6 col-12">
              <div className="testimonialbox mb-4 animate__animated animate__fadeInLeft">
                <div className="testimonial">
                  <img src="002522-150x150.jpg" alt="testimonial profile" />
                  <div className="rating">
                    <ul>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star text-white"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    "मैं इसे अपनी बात साझा करता हूं क्योंकि पिछले साल मैं यौन
                    समस्या और तनाव से पीड़ित हूं। उसके बाद मैंने इसे नेट आदि पर
                    पढ़ा। मेरे मित्र ने मुझे सुझाव दिया कि आप (श्रमण हेल्थकेयर)
                    के साथ चर्चा करेंगे, ताकि मैं क्लिनिक से इसका पूरा इलाज कर
                    सकूं। मुझे बहुत खुशी हो रही है और मेरी कार्यशैली अच्छी चल
                    रही है, इस समय बहुत अच्छा लग रहा है और मेरा परिवार भी बहुत
                    खुश है |"
                  </p>
                  <span>Santosh Kumar (Jalandhar)</span>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-12">
              <div className="testimonialbox mb-4 animate__animated animate__fadeInLeft">
                <div className="testimonial">
                  <img src="002522-150x150.jpg" alt="testimonial profile" />
                  <div className="rating">
                    <ul>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    मैं पिछले 12 वर्षों से शीघ्रपतन की समस्या से परेशान हूं। मैं
                    इस समस्या के कारण अपना मानसिक संतुलन भी खो रहा था। जिससे
                    मेरी शादीशुदा जिंदगी में काफी परेशानी आ रही थी। लेकिन जब
                    मैंने शर्मन हेल्थकेयर से अपना लिया, तो मुझे उनकी दवाओं के
                    अच्छे परिणाम मिले और शीघ्रपतन की समस्या दूर हो गई। अब मैं हर
                    तरह से स्वस्थ हूं और मैं अपनी शादीशुदा जिंदगी अच्छे से जी
                    रही हूं। मुझे जीवन का नया पट्टा देने के लिए मैं शर्मन
                    हेल्थकेयर का बहुत आभारी हूं।
                  </p>
                  <span>Sh. Mohinder Singh (Canada)</span>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-12">
              <div className="testimonialbox mb-4 animate__animated animate__fadeInLeft">
                <div className="testimonial">
                  <img src="002522-150x150.jpg" alt="testimonial profile" />
                  <div className="rating">
                    <ul>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star text-white"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    "मुझे पिछले कुछ महीनों से स्वपन दोष की समस्या आ रही थी जिस
                    कारण मैं परेशान रहता था और सोचता था कहीं मुझे शादी के बाद
                    किसी तरह की समस्या न आ जाए। फिर मैंने अपनी यह परेशानी अपने
                    दोस्त को बताई तो उसने मुझे श्रमण हैल्थकेयर वालों का नम्बर
                    दिया। इनकी दवाई का कोर्स करने के बाद अब मैं 100 प्रतिशत ठीक
                    हूं। धन्यावाद श्रमण हैल्थकेयर।"
                  </p>
                  <span>रजिन्द्र कुमार (मुकेरिया)</span>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-12">
              <div className="testimonialbox mb-4 animate__animated animate__fadeInLeft">
                <div className="testimonial">
                  <img src="002522-150x150.jpg" alt="testimonial profile" />
                  <div className="rating">
                    <ul>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star text-white"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    "मैं पिछले कुछ सालों से अपनी पत्नी को बिस्तर पर संतुष्ट नहीं
                    कर पाता था। जिस कारण मेरे घर में कलेश रहता था। मैंने अपना कई
                    जगह से ईलाज करवाया लेकिन मुझे कहीं से भी कोई फायदा नहीं हुआ।
                    फिर मैंने श्रमण हैल्थकेयर का इश्तिहार देखा और इनसे संपर्क
                    किया। इनसे संपर्क करने के बाद मैंने इनके द्वारा बताया 2
                    महीने का सैक्स कमजोरी का कोर्स किया। कोर्स के बाद मेरी पत्नी
                    मेरे से पुरी तरह से संतुष्ट है और अब मेरे घर में किसी तरह का
                    कोई कलेश नहीं रहता। धन्यावाद श्रमण हैल्थकेयर। "
                  </p>
                  <span>राजिन्द्र सिंह (लंदन)</span>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-12">
              <div className="testimonialbox mb-4 animate__animated animate__fadeInLeft">
                <div className="testimonial">
                  <img src="002522-150x150.jpg" alt="testimonial profile" />
                  <div className="rating">
                    <ul>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star text-white"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    "मेरे विवाह को 6 महीने हो गए थे लेकिन मेरे पति मुझे टच तक
                    नहीं करते थे। मैंने उनका सैक्स प्रति मूड बनाने के लिए कई तरह
                    के हथकंडे अपनाए। वह हर बार कोई न कोई बहाना लगाकर भाग जाते
                    थे। पहले मुझे लगा कि उनका बाहर किसी औरत से संबंध हैं पर ऐसा
                    नहीं था। जब एक दिन मैंने उनसे खुल कर बात की तो उन्होंने
                    बताया कि उनका सैक्स करने को मन ही नहीं करता। यह मेरी लिए एक
                    अजीबोगरीब घटना थी। फिर मैंने अपनी सहेली से बात की उसने बताया
                    कि उसके पति को भी इसी तरह की परेशानी आ रही थी फिर उन्होंने
                    जालन्धर पंजाब स्थित श्रमण हैल्थकेयर वालों से इस बीमारी का
                    कोर्स करवाया था और वो एकदम ठीक हो गए हैं। मैंने भी इनके साथ
                    सम्पर्क करके अपने पति के लिए कोर्स मंगवाया और आज मेरे दो
                    बच्चें हैं और हम एक अच्छा जीवन व्यतीत कर रहे हैं। धन्यावाद
                    श्रमण हैल्थकेयर। "
                  </p>
                  <span>सुरजीत कौर (सरी कैनेडा)</span>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-12">
              <div className="testimonialbox mb-4 animate__animated animate__fadeInLeft">
                <div className="testimonial">
                  <img src="002522-150x150.jpg" alt="testimonial profile" />
                  <div className="rating">
                    <ul>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star text-white"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    "मेरी आयु 25 साल है। मैं अपने कम वजन/कमजोर सेहत के कारण अपने
                    दोस्तों, रिश्तेदारों मे मजाक का पात्र बनता था और साथ में
                    मुझे शादी के लिए कोई रिश्ता भी नहीं आ रहा था। मैं डिप्रेशन
                    का शिकार हो रहा था। तभी मैंने श्रमण हैल्थकेयर का इश्तिहार
                    देखा। मैंने इनके द्वारा बताया वजन बढ़ाने का कोर्स किया।
                    जिससे मेरा वजन 12 से 14 किलो 3 महीने में बढ़ गया हैं और अब
                    मैं आर्कषित दिखने लगा हुआ है। अब मुहल्ले में सबसे सुन्दर
                    मेरी बीवी है और जो दोस्त मेरा मजाक बनाते थे वो अब मुझसे जलने
                    लगे हैं। अगर आप भी कम वजन के कारण मेरी तरह किसी भी परेशानी
                    से गुजर रहे हो तो आप अभी श्रमण हैल्थकेयर से सम्पर्क करें।
                    धन्यवाद श्रमण हैल्थकेयर। "
                  </p>
                  <span>करण (लुधियाना)</span>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-12">
              <div className="testimonialbox mb-4 animate__animated animate__fadeInLeft">
                <div className="testimonial">
                  <img src="002522-150x150.jpg" alt="testimonial profile" />
                  <div className="rating">
                    <ul>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star text-white"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    "मेरी आयु 40 साल है। मैं एक बैंक कर्मचारी हूं। मुझे पिछले
                    2-3 सालों से गठिया की परेशानी आ रही थी। जिस कारण मुझे चलने,
                    काम करने में बड़ी परेशानी होती थी। मैंने कई अंग्रेजी दवाईयों
                    तथा क्रीमों का इस्तेमाल किया। जिसको सेवन करने या लगाने के
                    बाद दर्द तो चला जाता था लेकिन परेशानी वहीं की वहीं थी और मैं
                    स्ट्रीराओड जैसी दवाईयों का शिकार होती जा रही थी। फिर किसी ने
                    मुझे श्रमण हैल्थकेयर वालों के बारे में बताया। मैंने इन्हें
                    कॉल करके आ रही परेशानी के बारे में बताया। इन्होंने मेरी
                    परेशानी के मुताबिक दवाई का कोर्स बनाकर भेजा। कोर्स करने के
                    साथ-साथ मेरा जोड़ो का दर्द ठीक होता गया और कोर्स खत्म होने
                    के बाद दर्द पुरी तरह से खत्म हो गया और अब मैं सुबह सैर करने
                    के लिए भी जा रही हूं और एक अच्छा जीवन व्यतीत कर रही हूं।
                    धन्यवाद श्रमण हैल्थकेयर। "
                  </p>
                  <span>सविता (पटियाला)</span>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* punjabi section     */}
        {props.lang === "pb" ? (
          <div className="row">
            <div className="col-sm-12 mb-5 animate__animated animate__fadeInLeft d-none">
              <h4>Shraman Healthcare : Testimonials</h4>
            </div>
            <div className="col-sm-6 col-12">
              <div className="testimonialbox mb-4 animate__animated animate__fadeInLeft">
                <div className="testimonial">
                  <img src="002522-150x150.jpg" alt="testimonial profile" />
                  <div className="rating">
                    <ul>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star text-white"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    ਮੈਂ ਇਸ ਨੂੰ ਆਪਣਾ ਦ੍ਰਿਸ਼ਟੀਕੋਣ ਸਾਂਝਾ ਕਰਦਾ ਹਾਂ ਕਿਉਂਕਿ ਪਿਛਲੇ ਸਾਲ
                    ਮੈਂ ਜਿਨਸੀ ਸਮੱਸਿਆ ਅਤੇ ਤਣਾਅ ਤੋਂ ਦੁਖੀ ਹਾਂ. ਇਸਤੋਂ ਬਾਅਦ ਮੈਂ ਇਸਨੂੰ
                    ਨੈੱਟ ਆਦਿ ਤੇ ਪੜ੍ਹਿਆ. ਮੇਰਾ ਦੋਸਤ ਮੈਨੂੰ ਸੁਝਾਅ ਦਿੰਦਾ ਹੈ ਕਿ ਤੁਸੀਂ
                    (ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ) ਨਾਲ ਵਿਚਾਰ ਕਰੋਗੇ ਤਾਂ ਕਿ ਮੈਂ ਇਸ ਦਾ ਸਾਰਾ ਇਲਾਜ
                    ਕਲੀਨਿਕ ਤੋਂ ਲਵਾਂ ਤਾਂ ਮੈਂ ਬਹੁਤ ਖੁਸ਼ ਮਹਿਸੂਸ ਕਰਦਾ ਹਾਂ ਅਤੇ ਮੇਰੀ
                    ਕਾਰਜਸ਼ੈਲੀ ਚੱਲ ਰਹੀ ਹੈ ਇਸ ਵਾਰ ਬਹੁਤ ਚੰਗਾ ਮਹਿਸੂਸ ਹੋਇਆ ਹੈ ਅਤੇ
                    ਮੇਰਾ ਪਰਿਵਾਰ. ਵੀ ਬਹੁਤ ਖੁਸ਼ ਹੈ |
                  </p>
                  <span>Santosh Kumar (Jalandhar)</span>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-12">
              <div className="testimonialbox mb-4 animate__animated animate__fadeInLeft">
                <div className="testimonial">
                  <img src="002522-150x150.jpg" alt="testimonial profile" />
                  <div className="rating">
                    <ul>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    ਮੈਂ ਪਿਛਲੇ 12 ਸਾਲਾਂ ਤੋਂ ਸ਼ੀਘਰਪਤਨ ਦੀ ਸੱਮਸਿਆ ਤੋਂ ਪਰੇਸ਼ਾਨ ਸੀ। ਇਸ
                    ਸੱਮਸਿਆ ਦੇ ਕਾਰਨ ਮੈਂ ਆਪਣਾ ਮਾਨਸਿਕ ਸੰਤੁਲਨ ਵੀ ਗਵਾ ਰਿਹਾ ਸੀ। ਜਿਸ
                    ਨਾਲ ਮੇਰੇ ਵਿਵਾਹਿਕ ਜੀਵਨ ਵਿੱਚ ਕਾਫ਼ੀ ਪਰੇਸ਼ਾਨੀ ਆ ਰਹੀ ਸੀ। ਪਰ ਜਦੋਂ
                    ਮੈਂ ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ ਤੋਂ ਆਪਣਾ ਕਰਵਾਇਆ ਤਾਂ ਇਹਨਾਂ ਦੀਆਂ ਦਵਾਈਆਂ ਤੋਂ
                    ਮੈਨੂੰ ਵਧੀਆ ਨਤੀਜੇ ਮਿਲੇ ਅਤੇ ਮੇਰੀ ਸ਼ੀਘਰਪਤਨ ਦੀ ਸੱਮਸਿਆ ਦੂਰ ਹੋ ਗਈ।
                    ਹੁਣ ਮੈਂ ਹਰ ਤਰ੍ਹਾਂ ਨਾਲ ਤੰਦਰੁਸਤ ਹਾਂ ਅਤੇ ਮੈਂ ਆਪਣਾ ਵਿਵਾਹਿਕ ਜੀਵਨ
                    ਚੰਗੀ ਤਰ੍ਹਾਂ ਨਾਲ ਬਿਤਾ ਰਿਹਾ ਹਾਂ। ਮੈਂ ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ ਦਾ ਬਹੁਤ
                    ਧੰਨਵਾਦੀ ਹਾਂ ਜਿਹਨਾਂ ਨੇ ਮੈਨੂੰ ਨਵਾਂ ਜੀਵਨ ਦਿੱਤਾ।
                  </p>
                  <span>Sh. Mohinder Singh (Canada)</span>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-12">
              <div className="testimonialbox mb-4 animate__animated animate__fadeInLeft">
                <div className="testimonial">
                  <img src="002522-150x150.jpg" alt="testimonial profile" />
                  <div className="rating">
                    <ul>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    " ਮੈਨੂੰ ਪਿਛਲੇ ਕੁਝ ਮਹੀਨਿਆਂ ਤੋਂ ਸੰਪਨ ਦੋਸ਼ ਦੀ ਸਮੱਸਿਆ ਆ ਰਹੀ ਸੀ,
                    ਜਿਸ ਕਾਰਨ ਮੈਂ ਪਰੇਸ਼ਾਨ ਹੋ ਜਾਂਦਾ ਸੀ ਅਤੇ ਸੋਚਦਾ ਸੀ ਕਿ ਵਿਆਹ ਤੋਂ
                    ਬਾਅਦ ਮੈਨੂੰ ਕਿਸੇ ਕਿਸਮ ਦੀ ਸਮੱਸਿਆ ਨ ਆ ਜਾਵੇ। ਫਿਰ ਮੈਂ ਆਪਣੇ ਦੋਸਤ
                    ਨੂੰ ਇਸ ਸਮੱਸਿਆ ਬਾਰੇ ਦੱਸਿਆ, ਉਸਨੇ ਮੈਨੂੰ ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ ਦਾ ਨੰਬਰ
                    ਦਿੱਤਾ। ਉਨ੍ਹਾਂ ਦੀ ਦਵਾਈ ਦਾ ਕੋਰਸ ਕਰਨ ਤੋਂ ਬਾਅਦ ਮੈਂ ਹੁਣ 100
                    ਪ੍ਰਤੀਸ਼ਤ ਠੀਕ ਹਾਂ। ਸ਼ਰਮਣ ਹੈਲਥਕੇਅਰ ਦਾ ਧੰਨਵਾਦ।"
                  </p>
                  <span>ਰਾਜਿੰਦਰ ਕੁਮਾਰ (ਮੁਕੇਰੀਆ)</span>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-12">
              <div className="testimonialbox mb-4 animate__animated animate__fadeInLeft">
                <div className="testimonial">
                  <img src="002522-150x150.jpg" alt="testimonial profile" />
                  <div className="rating">
                    <ul>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    " ਅੱਜ ਮੇਰੇ ਵਿਆਹ ਨੂੰ 6 ਸਾਲ ਹੋ ਗਏ ਹਨ। ਮੈਂ ਪਿਛਲੇ ਕੁਝ ਸਾਲਾਂ ਤੋਂ
                    ਆਪਣੀ ਪਤਨੀ ਨੂੰ ਬਿਸਤਰੇ 'ਤੇ ਸੰਤੁਸ਼ਟ ਨਹੀਂ ਕਰ ਸਕਿਆ। ਜਿਸ ਕਾਰਨ ਮੇਰੇ
                    ਘਰ ਕਲੇਸ਼ ਰਹਿੰਦਾ ਸੀ। ਮੈਂ ਆਪਣਾ ਇਲਾਜ਼ ਕਈ ਥਾਵਾਂ ਤੋਂ ਕਰਵਾਇਆ ਪਰ
                    ਮੈਨੂੰ ਕਿਧਰੇ ਵੀ ਕੋਈ ਲਾਭ ਨਹੀਂ ਮਿਲ ਸਕਿਆ। ਫਿਰ ਮੈਂ ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ
                    ਦਾ ਇਸ਼ਤਿਹਾਰ ਵੇਖਿਆ ਅਤੇ ਉਨ੍ਹਾਂ ਨਾਲ ਸੰਪਰਕ ਕੀਤਾ। ਉਨ੍ਹਾਂ ਨਾਲ ਸੰਪਰਕ
                    ਕਰਨ ਤੋਂ ਬਾਅਦ ਮੈਂਨੂੰ ਉਨ੍ਹਾਂ ਨੇ 2 ਮਹੀਨੇ ਦਾ ਸੈਕਸ ਕਮਜੋਰੀ ਦਾ ਕੋਰਸ
                    ਕਰਨ ਲਈ ਕਿਹਾ। ਕੋਰਸ ਤੋਂ ਬਾਅਦ ਮੇਰੀ ਪਤਨੀ ਮੇਰੇ ਨਾਲ ਪੂਰੀ ਤਰ੍ਹਾਂ
                    ਸੰਤੁਸ਼ਟ ਹੈ ਅਤੇ ਹੁਣ ਮੇਰੇ ਘਰ ਵਿੱਚ ਕਿਸੇ ਕਿਸਮ ਦਾ ਕਲੇਸ਼ ਨਹੀਂ ਹੈ।
                    ਧੰਨਵਾਦ ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ। "
                  </p>
                  <span>ਰਜਿੰਦਰ ਸਿੰਘ (ਲੰਡਨ)</span>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-12">
              <div className="testimonialbox mb-4 animate__animated animate__fadeInLeft">
                <div className="testimonial">
                  <img src="002522-150x150.jpg" alt="testimonial profile" />
                  <div className="rating">
                    <ul>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    " ਮੇਰੇ ਵਿਆਹ ਨੂੰ 6 ਮਹੀਨੇ ਹੋਏ ਹਨ ਪਰ ਮੇਰੇ ਪਤੀ ਮੈਨੂੰ ਹੱਥ ਤੱਕ
                    ਨਹੀਂ ਲਾਉਂਦੇ ਸਨ। ਮੈਂ ਉਨ੍ਹਾਂ ਦੇ ਮੂਡ ਨੂੰ ਬਣਾਉਣ ਲਈ ਬਹੁਤ ਸਾਰੀਆਂ
                    ਕੌਸ਼ਿਸ਼ਾਂ ਕੀਤੀਆਂ ਪਰ ਉਹ ਹਰ ਵਾਰ ਕੋਈ ਨਾ ਕੋਈ ਬਹਾਨਾ ਲਗਾ ਲੈਂਦੇ ਸੀ।
                    ਪਹਿਲਾਂ ਮੈਂ ਮਹਿਸੂਸ ਕੀਤਾ ਕਿ ਉਸ ਦਾ ਬਾਹਰ ਕਿਸੇ ਔਰਤ ਨਾਲ ਰਿਸ਼ਤਾ ਹੈ
                    ਪਰ ਅਜਿਹਾ ਨਹੀਂ ਸੀ। ਜਦੋਂ ਮੈਂ ਇਕ ਦਿਨ ਉਨ੍ਹਾਂ ਨਾਲ ਖੁੱਲ੍ਹ ਕੇ ਗੱਲ
                    ਕੀਤੀ ਤਾਂ ਉਨ੍ਹਾਂ ਨੇ ਮੈਨੂੰ ਕਿਹਾ ਕਿ ਉਨ੍ਹਾਂ ਦੀ ਸੈਕਸ ਕਰਨ ਲਈ ਕੋਈ
                    ਇੱਛਾ ਨਹੀਂ ਹੈ, ਮੇਰੇ ਲਈ ਇਹ ਇਕ ਅਜੀਬ ਘਟਨਾ ਸੀ। ਫੇਰ ਮੈਂ ਆਪਣੀ ਸਹੇਲੀ
                    ਨਾਲ ਗੱਲ ਕੀਤੀ, ਉਸਨੇ ਦੱਸਿਆ ਕਿ ਉਸਦੇ ਪਤੀ ਨੂੰ ਵੀ ਅਜਿਹੀਆਂ ਮੁਸ਼ਕਲਾਂ
                    ਦਾ ਸਾਹਮਣਾ ਕਰਨਾ ਪੈ ਰਿਹਾ ਸੀ, ਫਿਰ ਉਨ੍ਹਾਂ ਨੇ ਜਲੰਧਰ ਪੰਜਾਬ ਵਿੱਚ
                    ਸਥਿਤ ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ ਤੋਂ ਬਿਮਾਰੀ ਦਾ ਕੋਰਸ ਕੀਤਾ ਅਤੇ ਹੁਣ ਉਹ ਬਿਲਕੁਲ
                    ਠੀਕ ਹੋ ਗਏ ਹਨ। ਮੈਂ ਵੀ ਉਨ੍ਹਾਂ ਨਾਲ ਸੰਪਰਕ ਕੀਤਾ ਅਤੇ ਆਪਣੇ ਪਤੀ ਲਈ
                    ਕੋਰਸ ਮੰਗਵਾਇਆ ਅਤੇ ਅੱਜ ਮੇਰੇ ਦੋ ਬੱਚੇ ਹਨ ਅਤੇ ਅਸੀਂ ਚੰਗੀ ਜ਼ਿੰਦਗੀ ਜੀ
                    ਰਹੇ ਹਾਂ। ਧੰਨਵਾਦ ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ। "
                  </p>
                  <span>ਸੁਰਜੀਤ ਕੌਰ (ਸਰੀ ਕਨੇਡਾ)</span>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-12">
              <div className="testimonialbox mb-4 animate__animated animate__fadeInLeft">
                <div className="testimonial">
                  <img src="002522-150x150.jpg" alt="testimonial profile" />
                  <div className="rating">
                    <ul>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    " ਮੈਂ 25 ਸਾਲਾਂ ਦਾ ਹਾਂ। ਮੈਂ ਆਪਣੇ ਘੱਟ ਭਾਰ / ਕਮਜ਼ੋਰ ਸਿਹਤ ਕਾਰਨ
                    ਆਪਣੇ ਦੋਸਤਾਂ, ਰਿਸ਼ਤੇਦਾਰਾਂ ਦਾ ਮਜ਼ਾਕ ਦਾ ਕਾਰਨ ਬਣਦਾ ਸੀ ਅਤੇ ਮੇਰੇ
                    ਵਿਆਹ ਲਈ ਕੋਈ ਰਿਸ਼ਤਾ ਨਹੀਂ ਆ ਰਿਹਾ ਸੀ। ਮੈਂ ਡਿਪਰੇਸ਼ਨ ਦਾ ਸ਼ਿਕਾਰ ਹੋ
                    ਰਿਹਾ ਸੀ। ਫੇਰ ਮੈਂ ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ ਦਾ ਇਸ਼ਤਿਹਾਰ ਵੇਖਿਆ। ਮੈਂ ਉਨ੍ਹਾਂ
                    ਦੁਆਰਾ ਦੱਸਿਆ ਭਾਰ ਵਧਾਉਣ ਦਾ ਕੋਰਸ ਕੀਤਾ ਜਿਸ ਕਾਰਨ ਮੇਰਾ ਭਾਰ 3
                    ਮਹੀਨਿਆਂ ਵਿੱਚ 12 ਤੋਂ 14 ਕਿਲੋ ਹੋ ਗਿਆ ਅਤੇ ਹੁਣ ਮੈਂ ਆਕਰਸ਼ਕ ਦਿਖ
                    ਰਿਹਾ ਹਾਂ। ਹੁਣ ਮੁਹੱਲੇ ਵਿੱਚ ਸਭ ਤੋਂ ਸੌਹਣੀ ਮੇਰੀ ਪਤਨੀ ਹੈ ਅਤੇ ਜੋ
                    ਦੋਸਤ ਮੇਰਾ ਮਜ਼ਾਕ ਉਡਾਉਂਦੇ ਸਨ ਮੇਰੇ ਨਾਲ ਸੜਨ ਲੱਗ ਪਏ ਹਨ। ਜੇ ਤੁਸੀਂ
                    ਵੀ ਭਾਰ ਘੱਟ ਹੋਣ ਕਰਕੇ ਮੇਰੇ ਵਾਂਗ ਕਿਸੇ ਸਮੱਸਿਆ ਤੋਂ ਗੁਜ਼ਰ ਰਹੇ ਹੋ,
                    ਤਾਂ ਤੁਹਾਨੂੰ ਹੁਣ ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ ਨਾਲ ਸੰਪਰਕ ਕਰਨਾ ਚਾਹੀਦਾ ਹੈ।
                    ਧੰਨਵਾਦ ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ। "
                  </p>
                  <span>ਕਰਨ (ਲੁਧਿਆਣਾ)</span>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-12">
              <div className="testimonialbox mb-4 animate__animated animate__fadeInLeft">
                <div className="testimonial">
                  <img src="002522-150x150.jpg" alt="testimonial profile" />
                  <div className="rating">
                    <ul>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    " ਮੇਰੀ ਉਮਰ40 ਸਾਲਾਂ ਦੀ ਹੈ। ਮੈਂ ਇੱਕ ਬੈਂਕ ਕਰਮਚਾਰੀ ਹਾਂ। ਮੈਨੂੰ
                    ਪਿਛਲੇ 2-3 ਸਾਲਾਂ ਤੋਂ ਗਠੀਏ ਦੀ ਸਮੱਸਿਆ ਆ ਰਹੀ ਸੀ। ਜਿਸ ਕਾਰਨ ਮੈਨੂੰ
                    ਚੱਲਣ, ਕੰਮ ਕਰਨ ਵਿਚ ਬਹੁਤ ਮੁਸ਼ਕਲ ਆਉਂਦੀ ਸੀ। ਮੈਂ ਬਹੁਤ ਸਾਰੀਆਂ
                    ਅੰਗਰੇਜ਼ੀ ਦਵਾਈਆਂ ਅਤੇ ਕਰੀਮਾਂ ਦੀ ਵਰਤੋਂ ਕੀਤੀ। ਜਿਨ੍ਹਾਂ ਦਾ ਸੇਵਨ ਕਰਨ
                    ਜਾਂ ਲਗਾਉਣ ਤੋਂ ਬਾਅਦ ਦਰਦ ਤਾਂ ਚਲਾ ਜਾਂਦਾ ਸੀ, ਪਰ ਸਮੱਸਿਆ ਉਥੇ ਦੀ
                    ਉੱਥੇ ਸੀ ਅਤੇ ਮੈਂ ਸਟੀਰਾਉਡ ਵਰਗੀਆਂ ਦਵਾਈਆਂ ਦਾ ਸ਼ਿਕਾਰ ਹੋ ਰਹੀ ਸੀ।
                    ਫਿਰ ਕਿਸੇ ਨੇ ਮੈਨੂੰ ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ ਬਾਰੇ ਦੱਸਿਆ। ਮੈਂ ਉਨ੍ਹਾਂ ਨੂੰ
                    ਕਾਲ ਕਰਕੇ ਪਰੇਸ਼ਾਨੀ ਬਾਰੇ ਦੱਸਿਆ। ਇਨ੍ਹਾਂ ਨੇ ਮੇਰੀ ਸਮੱਸਿਆ ਦੇ ਅਨੁਸਾਰ
                    ਦਵਾਈ ਦਾ ਇੱਕ ਕੋਰਸ ਬਣਾ ਕੇ ਭੇਜਿਆ। ਕੋਰਸ ਕਰਨ ਦੇ ਨਾਲ-ਨਾਲ ਮੇਰਾ
                    ਜੋੜਾਂ ਦਾ ਦਰਦ ਠੀਕ ਹੁੰਦਾ ਗਿਆ ਅਤੇ ਕੋਰਸ ਖਤਮ ਹੋਣ ਤੋਂ ਬਾਅਦ ਦਰਦ
                    ਪੂਰੀ ਤਰ੍ਹਾਂ ਖਤਮ ਹੋ ਗਿਆ ਅਤੇ ਹੁਣ ਮੈਂ ਸਵੇਰੇ ਸੈਰ ਕਰਨ ਵੀ ਜਾ ਰਹੀ
                    ਹਾਂ ਅਤੇ ਚੰਗੀ ਜ਼ਿੰਦਗੀ ਜੀ ਰਹੀ ਹਾਂ। ਧੰਨਵਾਦ ਸ਼ਰਮਨ ਹੈਲਥਕੇਅਰ। "
                  </p>
                  <span>ਸਵਿਤਾ (ਪਟਿਆਲਾ)</span>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
}
