import React, { useState, useEffect } from "react";
import Header from "./Header";
import Banner from './Banner';
import Services from "./Services";
import About from "./About";
import News from "./News";
import Stats from './Stats'
import TreatmentWeOffer from './TreatmentWeOffer';
import TreatmentOffered from "./TreatmentOffered";
import Testimonialvideo from "./Testimonial-video";
import Footer from "./Footer";
import MapRoute from "./MapRoute";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-171309197-1');

export default function Homepage(props) {
  let [lang, setLang] = useState("en");
  // const [comp, updateComponent] = useState(null);

  useEffect(() => {
  });

  useEffect(() => {
    lang = localStorage.getItem("lang");
    ReactGA.pageview(`/homepage ${lang}` );
    setLang(lang);
  }, [lang]);

  function changeLang(e) {
    localStorage.setItem("lang", e.target.value);
    setLang(e.target.value);
  }

  return (
    <div className="main-site">
      <Header {...props} changeLang={changeLang} lang={lang} />
      <Banner lang={lang}/>
      {/* <News {...props} changeLang={changeLang} lang={lang}/> */}
      <Services lang={lang} />
      <TreatmentOffered lang={lang} />
      <TreatmentWeOffer {...props} lang={lang}/>
      <About changeLang={changeLang} lang={lang} />
      <Testimonialvideo  changeLang={changeLang} lang={lang} />
      <Stats {...props} lang={lang}/>
      <MapRoute />
      <Footer lang={lang} />
    </div>
  );
}
