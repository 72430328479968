import SexProblem from './SexProblem';
import SexWeakness from './SexWeakness';
import Nightfall from './Nightfall';
import LowSpermCount from './LowSpermCount';
import LackOfDesireInMen from './LackOfDesireInMen';
import WeightGain from './WeightGain';
import Leukorrhea from './Leukorrhea';
import LexityOfPenis from './LexityOfPenis';
import WeightLoss from './WeightLoss';
import Impotency from './Impotency';
import ArthritisJoint from './ArthritisJoint';
import LackOfDesireInWomen from './LackOfDesireInWomen';
import BreastEnlargement from './BreastEnlargement';
import EarlyDischarge from './EarlyDischarge';
import PenisEnlargement from './PenisEnlargement';
import PrematureEjaculation from './PrematureEjaculation';
import Sugar from './Sugar';

const Treatment = {
    SexProblem,
    SexWeakness,
    Nightfall,
    LowSpermCount,
    LackOfDesireInMen,
    WeightGain,
    Leukorrhea,
    LexityOfPenis,
    WeightLoss,
    Impotency,
    ArthritisJoint,
    LackOfDesireInWomen,
    BreastEnlargement,
    EarlyDischarge,
    PenisEnlargement,
    PrematureEjaculation,
    Sugar
};

export default Treatment;

