import React from 'react';

export default function Impotency() {
    return(
        <div className="treatmentContent">
            <h4>Eractile Dysfunction</h4>
            <p>Impotence  is a type of sexual dysfunction, which is mainly characterized by one’s inability to develop or maintain an erection of the penis during sexual intercourse. Impotency is not just limited to perform sexual activity, but the inability to procreate naturally is also called impotency. There are a number of conditions, both mental and physical, which prevent a man from performing sexually.</p>
            <p>The most important caues of impotence are cardiovascular diseases, neurogenic disorders, side effects of various drugs and alcohol, psychologicial causes: performance anxiety, stress and mental disorders. Impotence is also caused as a result of ageing. It is four times more common in men in their sixties than those in their forties. Smoking is also a key cause of impotence as it promotes arterial narrowing.</p>
            <p>Impotnece can be both permanent or temporary and can be total or partial too. Ayurveda suggests multiple cures for the treatment of impotence. The medical practitioner at Shraman Health Care have come up with ‘Vajikarma Aushadhis’, which are the medicines to cure impotency. Along with a high protein diet, the people suffering from impotence or erectile dysfuction are required to consume these Ayurvedic medicines. These medicines use natural herbs in their treatment which are helpful in managing the problem. As this treatment  uses natural herbs, there are no side effects. These medcines strengthen the muscles, veins and nerves and help one to regain proper erection and also have a lasting impact of one’s sexual health.</p>
            <h5>Payments mode for Indian Customers:</h5>
            <p><strong>Cash On Delivery:</strong> A payment through Paytm is also accepted on +91 98728-13954. Payment can be deposited in our bank account:</p>

            <div className="bankdetails mb-3">
                <div className="row">
                    <div className="col-sm-6 col-12">
                        <h5>
                            Shraman Health Care ICICI BANK <br/>
                            A/c No. 151205000555 <br/>
                            IFSC Code. ICIC0001512 <br/>
                            Branch. Civil Lines Jalandhar <br/>
                        </h5>
                    </div>
                    <div className="col-sm-6 col-12">
                        <h5>
                            Punjab National Bank, <br/>
                            Name: Dr. Ramdev Kapoor, <br/>
                            Account Number: 0235002101719421 <br/>
                            IFSC CODE: PUNB0023500 <br/>
                        </h5>
                    </div>
                </div>
            </div>

            <p>For online order call us on +91 93177-70773, +91 98728-13954, 0181-4153002 and WhatsApp us the receipt after making payment and send us your address.</p>

            <h5>Payment mode for Foreign Customers:</h5>
            <p>Payments through Western Money Union, Money Gram, and Express Money with the name <strong>Suhel Jain</strong> are accepted.</p>
        </div>
    );
}