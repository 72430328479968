import React from "react";
import ReactGA from "react-ga";

ReactGA.initialize("UA-171309197-1");

export default function TreatmentWeOffer(props) {
  return (
    <section className="treatmentoffer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center mb-4" hidden>
            <h4>Treatment We Offer</h4>
            <h5>We Provide You The Best Treatment For Sex Problem</h5>
          </div>
          <div className="col-sm-3 col-6">
            {props.lang === "en" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/erectile.jpeg"
                  alt="eractile dysfuntion"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/erectile-dysfunction")
                  }
                />
              </div>
            ) : null}
            {props.lang === "hi" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed39.jpg"
                  alt="eractile dysfuntion"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/erectile-dysfunction")
                  }
                />
              </div>
            ) : null}
            {props.lang === "pb" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed2.jpg"
                  alt="eractile dysfuntion"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/erectile-dysfunction")
                  }
                />
              </div>
            ) : null}
          </div>
          <div className="col-sm-3 col-6">
            {props.lang === "en" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed1.jpg"
                  alt="premture ejaculation"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/premature-ejaculation")
                  }
                />
              </div>
            ) : null}
            {props.lang === "hi" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed3.jpg"
                  alt="premture ejaculation"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/premature-ejaculation")
                  }
                />
              </div>
            ) : null}
            {props.lang === "pb" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed40.jpg"
                  alt="premture ejaculation"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/premature-ejaculation")
                  }
                />
              </div>
            ) : null}
          </div>
          <div className="col-sm-3 col-6">
            {props.lang === "en" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed41.jpg"
                  alt="low libido in men"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/lack-of-desire-in-men")
                  }
                />
              </div>
            ) : null}
            {props.lang === "hi" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed4.jpg"
                  alt="low libido in men"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/lack-of-desire-in-men")
                  }
                />
              </div>
            ) : null}
            {props.lang === "pb" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed6.jpg"
                  alt="low libido in men"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/lack-of-desire-in-men")
                  }
                />
              </div>
            ) : null}
          </div>
          <div className="col-sm-3 col-6">
            {props.lang === "en" ? (
              <div className="tile mb-3 animate__animated animate__fadeInRight">
                <img
                  src="treatment/unnamed5.jpg"
                  alt="penis enlargement"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/penis-enlargement")
                  }
                />
              </div>
            ) : null}
            {props.lang === "hi" ? (
              <div className="tile mb-3 animate__animated animate__fadeInRight">
                <img
                  src="treatment/unnamed7.jpg"
                  alt="penis enlargement"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/penis-enlargement")
                  }
                />
              </div>
            ) : null}
            {props.lang === "pb" ? (
              <div className="tile mb-3 animate__animated animate__fadeInRight">
                <img
                  src="treatment/unnamed8.jpg"
                  alt="penis enlargement"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/penis-enlargement")
                  }
                />
              </div>
            ) : null}
          </div>
          <div className="col-sm-3 col-6">
            {props.lang === "en" ? (
              <div className="tile mb-3 animate__animated animate__fadeInRight">
                <img
                  src="treatment/unnamed9.jpg"
                  alt="low sperm count"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/low-sperm-count")
                  }
                />
              </div>
            ) : null}
            {props.lang === "hi" ? (
              <div className="tile mb-3 animate__animated animate__fadeInRight">
                <img
                  src="treatment/unnamed10.jpg"
                  alt="low sperm count"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/low-sperm-count")
                  }
                />
              </div>
            ) : null}
            {props.lang === "pb" ? (
              <div className="tile mb-3 animate__animated animate__fadeInRight">
                <img
                  src="treatment/unnamed11.jpg"
                  alt="low sperm count"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/low-sperm-count")
                  }
                />
              </div>
            ) : null}
          </div>
          <div className="col-sm-3 col-6">
            {props.lang === "en" ? (
              <div className="tile mb-3 animate__animated animate__fadeInRight">
                <img
                  src="treatment/unnamed12.jpg"
                  alt="nightfall"
                  className="img-fluid"
                  onClick={() => props.history.push("/treatment/nightfall")}
                />
              </div>
            ) : null}
            {props.lang === "hi" ? (
              <div className="tile mb-3 animate__animated animate__fadeInRight">
                <img
                  src="treatment/unnamed13.jpg"
                  alt="nightfall"
                  className="img-fluid"
                  onClick={() => props.history.push("/treatment/nightfall")}
                />
              </div>
            ) : null}
            {props.lang === "pb" ? (
              <div className="tile mb-3 animate__animated animate__fadeInRight">
                <img
                  src="treatment/unnamed14.jpg"
                  alt="nightfall"
                  className="img-fluid"
                  onClick={() => props.history.push("/treatment/nightfall")}
                />
              </div>
            ) : null}
          </div>
          <div className="col-sm-3 col-6">
            {props.lang === "en" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed15.jpg"
                  alt="weight gain"
                  className="img-fluid"
                  onClick={() => props.history.push("/treatment/weight-gain")}
                />
              </div>
            ) : null}
            {props.lang === "hi" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed16.jpg"
                  alt="weight gain"
                  className="img-fluid"
                  onClick={() => props.history.push("/treatment/weight-gain")}
                />
              </div>
            ) : null}
            {props.lang === "pb" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed17.jpg"
                  alt="weight gain"
                  className="img-fluid"
                  onClick={() => props.history.push("/treatment/weight-gain")}
                />
              </div>
            ) : null}
          </div>
          <div className="col-sm-3 col-6">
            {props.lang === "en" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed18.jpg"
                  alt="weight loss"
                  className="img-fluid"
                  onClick={() => props.history.push("/treatment/weight-loss")}
                />
              </div>
            ) : null}
            {props.lang === "hi" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed19.jpg"
                  alt="weight loss"
                  className="img-fluid"
                  onClick={() => props.history.push("/treatment/weight-loss")}
                />
              </div>
            ) : null}
            {props.lang === "pb" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed20.jpg"
                  alt="weight loss"
                  className="img-fluid"
                  onClick={() => props.history.push("/treatment/weight-loss")}
                />
              </div>
            ) : null}
          </div>
          <div className="col-sm-3 col-6">
            {props.lang === "en" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed21.jpg"
                  alt="low libido in women"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/lack-of-desire-in-women")
                  }
                />
              </div>
            ) : null}
            {props.lang === "hi" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed22.jpg"
                  alt="low libido in women"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/lack-of-desire-in-women")
                  }
                />
              </div>
            ) : null}
            {props.lang === "pb" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed23.jpg"
                  alt="low libido in women"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/lack-of-desire-in-women")
                  }
                />
              </div>
            ) : null}
          </div>
          <div className="col-sm-3 col-6">
            {props.lang === "en" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed24.jpg"
                  alt="diabetes"
                  className="img-fluid"
                  onClick={() => props.history.push("/treatment/sugar")}
                />
              </div>
            ) : null}
            {props.lang === "hi" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed25.jpg"
                  alt="diabetes"
                  className="img-fluid"
                  onClick={() => props.history.push("/treatment/sugar")}
                />
              </div>
            ) : null}
            {props.lang === "pb" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed26.jpg"
                  alt="diabetes"
                  className="img-fluid"
                  onClick={() => props.history.push("/treatment/sugar")}
                />
              </div>
            ) : null}
          </div>
          <div className="col-sm-3 col-6">
            {props.lang === "en" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed27.jpg"
                  alt="breast enlargement"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/breast-enlargement")
                  }
                />
              </div>
            ) : null}
            {props.lang === "hi" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed28.jpg"
                  alt="breast enlargement"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/breast-enlargement")
                  }
                />
              </div>
            ) : null}
            {props.lang === "pb" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed29.jpg"
                  alt="breast enlargement"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/breast-enlargement")
                  }
                />
              </div>
            ) : null}
          </div>
          <div className="col-sm-3 col-6">
            {props.lang === "en" ? (
                <div className="tile mb-3 animate__animated animate__fadeInLeft">
                  <img
                    src="treatment/unnamed30.jpg"
                    alt="luekorreha"
                    className="img-fluid"
                    onClick={() => props.history.push("/treatment/leukorrhea")}
                  />
                </div>
            ) : null}
            {props.lang === "hi" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed31.jpg"
                  alt="luekorreha"
                  className="img-fluid"
                  onClick={() => props.history.push("/treatment/luekorreha")}
                />
              </div>
            ) : null}
            {props.lang === "pb" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed32.jpg"
                  alt="luekorreha"
                  className="img-fluid"
                  onClick={() => props.history.push("/treatment/leukorrhea")}
                />
              </div>
            ) : null}
          </div>
          <div className="col-sm-3 col-6">
            {props.lang === "en" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed35.jpg"
                  alt="joint pain"
                  className="img-fluid"
                  onClick={() => props.history.push("/treatment/arthritis-and-joint")}
                />
              </div>
            ) : null}
            {props.lang === "hi" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed33.jpg"
                  alt="joint pain"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/arthritis-and-joint")
                  }
                />
              </div>
            ) : null}
            {props.lang === "pb" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed34.jpg"
                  alt="joint pain"
                  className="img-fluid"
                  onClick={() =>
                    props.history.push("/treatment/arthritis-and-joint")
                  }
                />
              </div>
            ) : null}
          </div>
          <div className="col-sm-3 col-6">
            {props.lang === "en" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed36.jpg"
                  alt="dht"
                  className="img-fluid"
                  onClick={() => props.history.push("/treatment/sex-problem")}
                />
              </div>
            ) : null}
            {props.lang === "hi" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed37.jpg"
                  alt="dht"
                  className="img-fluid"
                  onClick={() => props.history.push("/treatment/sex-problem")}
                />
              </div>
            ) : null}
            {props.lang === "pb" ? (
              <div className="tile mb-3 animate__animated animate__fadeInLeft">
                <img
                  src="treatment/unnamed38.jpg"
                  alt="dht"
                  className="img-fluid"
                  onClick={() => props.history.push("/treatment/sex-problem")}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
}
