import React from 'react';

export default function Services(props) {

    return(
        <section className="ourservices">
            <div className="container">  
                <div className="row">
                    <div className="col-12" hidden>
                        <h4>Our Services</h4>
                    </div>
                    <div className="col-sm-2 col-6">
                        <div className="service-box animate__animated animate__fadeInDown">
                            <img src="icons/doctor.jpg" className="img-fluid" alt="experienced doctor" />
                        </div>
                        {
                            props.lang === "en" ? <p>Experienced Doctor</p> : null 
                        }
                        {
                            props.lang === "hi" ? <p>तर्जुेबेकार डाक्टर</p> : null 
                        }
                        {
                            props.lang === "pb" ? <p>ਤਜ਼ੁਰਬੇਕਾਰ ਡਾਕਟਰ</p> : null 
                        }
                    </div>
                    <div className="col-sm-2 col-6">
                        <div className="service-box animate__animated animate__fadeInDown">
                            <img src="icons/medicine.jpg" className="img-fluid" alt="customized medicine" />
                        </div>
                        {
                            props.lang === "en" ? <p>Customized Medicinces</p> : null 
                        }
                        {
                            props.lang === "hi" ? <p>अनुकूलित दवाई</p> : null 
                        }
                        {
                            props.lang === "pb" ? <p>ਅਨੁਕੂਲਿਤ ਦਵਾਈ</p> : null 
                        }
                    </div>
                    <div className="col-sm-2 col-6">
                        <div className="service-box animate__animated animate__fadeInDown">
                            <img src="icons/result.jpg" className="img-fluid" alt="result oriented" />
                        </div>
                        {
                            props.lang === "en" ? <p>Result Oriented</p> : null 
                        }
                        {
                            props.lang === "hi" ? <p>गारंटिड नतीजे</p> : null 
                        }
                        {
                            props.lang === "pb" ? <p>ਗਾਰੰਟਿਡ ਨਤੀਜੇ</p> : null 
                        }
                    </div>
                    <div className="col-sm-2 col-6">
                        <div className="service-box animate__animated animate__fadeInDown">
                            <img src="icons/vegetarian.jpg" className="img-fluid" alt="pure vegetarian" />
                        </div>
                        {
                            props.lang === "en" ? <p>Pure Vegetarian</p> : null 
                        }
                        {
                            props.lang === "hi" ? <p>शुद्ध शाकाहारी</p> : null 
                        }
                        {
                            props.lang === "pb" ? <p>ਸ਼ੁੱਧ ਸ਼ਾਕਾਹਾਰੀ</p> : null 
                        }
                    </div>
                    <div className="col-sm-2 col-6">
                        <div className="service-box animate__animated animate__fadeInDown">
                            <img src="icons/care.jpg" className="img-fluid" alt="Personal care" />
                        </div>
                        {
                            props.lang === "en" ? <p>Personal Care</p> : null 
                        }
                        {
                            props.lang === "hi" ? <p>निजी देखभाल</p> : null 
                        }
                        {
                            props.lang === "pb" ? <p>ਨਿੱਜੀ ਦੇਖਭਾਲ</p> : null 
                        }
                    </div>
                    <div className="col-sm-2 col-6">
                        <div className="service-box animate__animated animate__fadeInDown">
                            <img src="icons/privacy.jpg" className="img-fluid" alt="assured privacy" />
                        </div>
                        {
                            props.lang === "en" ? <p>Assured Privacy</p> : null 
                        }
                        {
                            props.lang === "hi" ? <p>गोपनीयता की गारंटी</p> : null 
                        }
                        {
                            props.lang === "pb" ? <p>ਗੋਪਨੀਯਤਾ ਦੀ ਗਾਰੰਟੀ </p> : null 
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}