import React from 'react'

export default function MapRoute() {
    return(
        <div className="map-route">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 p-0">
                        <iframe title="shraman healthcare address" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6816.256213245186!2d75.57986!3d31.327835!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1ba4a577d1db35b!2sShraman%20Health%20Care!5e0!3m2!1sen!2sin!4v1591031934793!5m2!1sen!2sin" style={{"width": "100%"}} height="450" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}