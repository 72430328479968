import React, { useEffect } from "react";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-171309197-1');

export default function SexProblem(props) {
  let { dhat } = props.data;
  useEffect(() => {
    ReactGA.pageview(props.location.pathname + " " + props.lang);

    window.scrollTo(0, 0);
  }, [props]);

  return (
    <div className="treatmentContent">
      {props.lang === "en" ? (
        <>
          <h4>{dhat.englishTitle}</h4>
          <div className="mt-4">
            {
              dhat.url ? (
                <iframe
                  title="dhat sex problem"
                  style={{ width: "100%" }}
                  height="550"
                  src={dhat.url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullscreen
                ></iframe>
              ) : null
            }
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: dhat.englishDescription }}
          />
        </>
      ) : null}

      {props.lang === "pb" ? (
        <>
          <h4>{dhat.punjabiTitle}</h4>
          <div className="mt-4">
            {
              dhat.url ? (
                <iframe
                  title="dhat sex problem"
                  style={{ width: "100%" }}
                  height="550"
                  src={dhat.url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullscreen
                ></iframe>
              ) : null
            }
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: dhat.punjabiDescription }}
          />
        </>
      ) : null}

      {/* hindi section goes here*/}

      {props.lang === "hi" ? (
        <>
          <h4>{dhat.hindiTitle}</h4>
          <div className="mt-4">
            {
              dhat.url ? (
                <iframe
                  title="dhat sex problem"
                  style={{ width: "100%" }}
                  height="550"
                  src={dhat.url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullscreen
                ></iframe>
              ) : (null)
            }
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: dhat.punjabiDescription }}
          />
        </>
      ) : null}
    </div>
  );
}
