import React, { useEffect } from "react";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-171309197-1');

export default function PenisEnlargement(props) {
  let { penisEnlargement } = props.data;
  useEffect(() => {
    ReactGA.pageview(props.location.pathname + " " + props.lang);

    window.scrollTo(0, 0);
  }, [props]);
  return (
    <div className="treatmentContent">
      {props.lang === "en" ? (
        <>
          <h4>{penisEnlargement.englishTitle}</h4>
          <div className="mt-4">
            {
              penisEnlargement.url ? (
                <iframe
                  title="lexity in penis"
                  style={{ width: "100%" }}
                  height="315"
                  src={penisEnlargement.url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullscreen
                ></iframe>
              ) : null
            }
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: penisEnlargement.englishDescription }}
          />
        </>
      ) : null}

      {props.lang === "pb" ? (
        <>
          <h4>{penisEnlargement.punjabiTitle}</h4>
          <div className="mt-4">
            {
              penisEnlargement.url ? (
                <iframe
                  title="lexity in penis"
                  style={{ width: "100%" }}
                  height="315"
                  src={penisEnlargement.url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullscreen
                ></iframe>
              ) : null
            }
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: penisEnlargement.punjabiDescription }}
          />
        </>
      ) : null}

      {props.lang === "hi" ? (
        <>
          <h4>{penisEnlargement.hindiTitle}</h4>
          <div className="mt-4">
            {
              penisEnlargement.url ? (
                <iframe
                  title="lexity in penis"
                  style={{ width: "100%" }}
                  height="315"
                  src={penisEnlargement.url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullscreen
                ></iframe>
              ) : null
            }
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: penisEnlargement.hindiDescription }}
          />ƒ
        </>
      ) : null}
    </div>
  );
}
