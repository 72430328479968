import React, { useState, useEffect } from "react";
import Header from "./Homepage/Header";
import Footer from "./Homepage/Footer";
import TreatmentComponents from "./TreatmentComponents";
import TreatmentBreadcrumbs from "./TreatmentComponents/TreatmentBreadCrumbs";
import TreatmentRoute from "./TreatmentRoute";
import { Route } from "react-router-dom";
import ReactGA from 'react-ga';
import { BASE_URL } from "./common/config";

ReactGA.initialize('UA-171309197-1');

export default function Treatment(props) {
  let [lang, setLang] = useState("en");
  const [data, setData] = useState({
    dhat: {
      englishTitle: '',
      englishDescription: '',
      url: null,
      hindiTitle: '',
      hindiDescription: '',
      punjabiTitle: '',
      punjabiDescription: ''
    },
    erectileDysfunction: {
      englishTitle: '',
      englishDescription: '',
      url: null,
      hindiTitle: '',
      hindiDescription: '',
      punjabiTitle: '',
      punjabiDescription: ''
    },
    prematureEjaculation: {
      englishTitle: '',
      englishDescription: '',
      url: null,
      hindiTitle: '',
      hindiDescription: '',
      punjabiTitle: '',
      punjabiDescription: ''
    },
    lowLibidoMale: {
      englishTitle: '',
      englishDescription: '',
      url: null,
      hindiTitle: '',
      hindiDescription: '',
      punjabiTitle: '',
      punjabiDescription: ''
    },
    penisEnlargement: {
      englishTitle: '',
      englishDescription: '',
      url: null,
      hindiTitle: '',
      hindiDescription: '',
      punjabiTitle: '',
      punjabiDescription: ''
    },
    lowSperm: {
      englishTitle: '',
      englishDescription: '',
      url: null,
      hindiTitle: '',
      hindiDescription: '',
      punjabiTitle: '',
      punjabiDescription: ''
    },
    nightFall: {
      englishTitle: '',
      englishDescription: '',
      url: null,
      hindiTitle: '',
      hindiDescription: '',
      punjabiTitle: '',
      punjabiDescription: ''
    },
    weightGain: {
      englishTitle: '',
      englishDescription: '',
      url: null,
      hindiTitle: '',
      hindiDescription: '',
      punjabiTitle: '',
      punjabiDescription: ''
    },
    weightLoss: {
      englishTitle: '',
      englishDescription: '',
      url: null,
      hindiTitle: '',
      hindiDescription: '',
      punjabiTitle: '',
      punjabiDescription: ''
    },
    diabetes: {
      englishTitle: '',
      englishDescription: '',
      url: null,
      hindiTitle: '',
      hindiDescription: '',
      punjabiTitle: '',
      punjabiDescription: ''
    },
    lowLibidoFemale: {
      englishTitle: '',
      englishDescription: '',
      url: null,
      hindiTitle: '',
      hindiDescription: '',
      punjabiTitle: '',
      punjabiDescription: ''
    },
    leukorrhea: {
      englishTitle: '',
      englishDescription: '',
      url: null,
      hindiTitle: '',
      hindiDescription: '',
      punjabiTitle: '',
      punjabiDescription: ''
    },
    breatEnlargement: {
      englishTitle: '',
      englishDescription: '',
      url: null,
      hindiTitle: '',
      hindiDescription: '',
      punjabiTitle: '',
      punjabiDescription: ''
    },
    jointPain:{
      englishTitle: '',
      englishDescription: '',
      url: null,
      hindiTitle: '',
      hindiDescription: '',
      punjabiTitle: '',
      punjabiDescription: ''
    }
  })
  // const [comp, updateComponent] = useState(null);

  useEffect(() => {
    // alert("Yes coming")
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    lang = localStorage.getItem("lang")
    // console.log()
    if (props.location.pathname === "/treatment") {
      console.log('inside main ', props);
      ReactGA.pageview(props.match.url + " " + lang);
    }
    setLang(lang)
  }, [lang])

  useEffect(fetchTreatments, []);

  function changeLang(e) {
    localStorage.setItem("lang", e.target.value)
    setLang(e.target.value);
  }

  function fetchTreatments() {
    fetch(BASE_URL + 'treatment')
      .then(res => res.json())
      .then((response) => {
        console.log(response)
        if (response.response) {
          setData(response.data[0])
        }
      })
      .catch((error) => { })
  }

  return (
    <div className="site-treatment">
      <Header {...props} changeLang={changeLang} lang={lang} />
      <TreatmentBreadcrumbs lang={lang} />
      <div className="treatment-content my-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12">
              <>
                <Route
                  path="/treatment/sex-problem"
                  render={() => <TreatmentComponents.SexProblem {...props} data={data} lang={lang} />}
                />
                <Route
                  path="/treatment/sex-weakness"
                  render={() => <TreatmentComponents.SexWeakness {...props} data={data} lang={lang} />}
                />
                <Route
                  path="/treatment/nightfall"
                  render={() => <TreatmentComponents.Nightfall {...props} data={data} lang={lang} />}
                />
                <Route
                  path="/treatment/low-sperm-count"
                  render={() => <TreatmentComponents.LowSpermCount {...props} data={data} lang={lang} />}
                />
                <Route
                  path="/treatment/lack-of-desire-in-men"
                  render={() => <TreatmentComponents.LackOfDesireInMen {...props} data={data} lang={lang} />}
                />
                <Route
                  path="/treatment/weight-gain"
                  render={() => <TreatmentComponents.WeightGain {...props} data={data} lang={lang} />}
                />
                <Route
                  path="/treatment/leukorrhea"
                  render={() => <TreatmentComponents.Leukorrhea {...props} data={data} lang={lang} />}
                />
                <Route
                  path="/treatment/erectile-dysfunction"
                  render={() => <TreatmentComponents.LexityOfPenis {...props} data={data} lang={lang} />}
                />
                <Route
                  path="/treatment/weight-loss"
                  render={() => <TreatmentComponents.WeightLoss {...props} data={data} lang={lang} />}
                />
                <Route
                  path="/treatment/impotency"
                  render={() => <TreatmentComponents.Impotency {...props} data={data} lang={lang} />}
                />
                <Route
                  path="/treatment/arthritis-and-joint"
                  render={() => (
                    <TreatmentComponents.ArthritisJoint {...props} data={data} lang={lang} />
                  )}
                />
                <Route
                  path="/treatment/lack-of-desire-in-women"
                  render={() => <TreatmentComponents.LackOfDesireInWomen {...props} data={data} lang={lang} />}
                />
                <Route
                  path="/treatment/breast-enlargement"
                  render={() => <TreatmentComponents.BreastEnlargement {...props} data={data} lang={lang} />}
                />
                <Route
                  path="/treatment/early-discharge"
                  render={() => <TreatmentComponents.EarlyDischarge {...props} data={data} lang={lang} />}
                />
                <Route
                  path="/treatment/penis-enlargement"
                  render={() => <TreatmentComponents.PenisEnlargement {...props} data={data} lang={lang} />}
                />
                <Route
                  path="/treatment/premature-ejaculation"
                  render={() => <TreatmentComponents.PrematureEjaculation {...props} data={data} lang={lang} />}
                />
                <Route
                  path="/treatment/sugar"
                  render={() => <TreatmentComponents.Sugar {...props} data={data} lang={lang} />}
                />
                <Route path="/treatment" render={() => <TreatmentRoute {...props} lang={lang} />} />
              </>
            </div>
          </div>
        </div>
      </div>
      <Footer lang={lang} />
    </div>
  );
}
