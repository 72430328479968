import React, { useEffect } from "react";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-171309197-1');

export default function Nightfall(props) {
  let { nightFall } = props.data;
  useEffect(() => {
    ReactGA.pageview(props.location.pathname + " " + props.lang);
    window.scrollTo(0, 0);
  }, [props]);
  return (
    <div className="treatmentContent">
      {props.lang === "en" ? (
        <>
          <h4>{nightFall.englishTitle}</h4>
          <div className="mt-4">
            {
              nightFall.url ? (
                <iframe
                  title="lexity in penis"
                  style={{ width: "100%" }}
                  height="315"
                  src={nightFall.url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullscreen
                ></iframe>
              ) : null
            }
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: nightFall.englishDescription }}
          />
        </>
      ) : null}

      {props.lang === "pb" ? (
        <>
          <h4>{nightFall.punjabiTitle}</h4>
          <div className="mt-4">
            {
              nightFall.url ? (
                <iframe
                  title="lexity in penis"
                  style={{ width: "100%" }}
                  height="315"
                  src={nightFall.url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullscreen
                ></iframe>
              ) : null
            }
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: nightFall.punjabiDescription }}
          />
        </>
      ) : null}

      {props.lang === "hi" ? (
        <>
          <h4>{nightFall.hindiTitle}</h4>
          <div className="mt-4">
            {
              nightFall.url ? (
                <iframe
                  title="lexity in penis"
                  style={{ width: "100%" }}
                  height="315"
                  src={nightFall.url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullscreen
                ></iframe>
              ) : null
            }
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: nightFall.hindiDescription }}
          />ƒ
        </>
      ) : null}
    </div>
  )
}
